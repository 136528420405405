import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor() {}

  consolelog(message?: any, ...optionalParams: any[]) {
    if (environment.enableLogger && !environment.production) {
      // eslint-disable-next-line no-console
      console.log(message, optionalParams);
    }
  }
}
