import { Action, createReducer, on, ActionReducerMap, State } from '@ngrx/store';
import * as GlobalActions from '@actions/global.actions';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from './custom-route-serializer';
import { Orientation } from '@app/animations';

export const globalFeatureKey = 'global';

// GlobalState interface
export interface GlobalState {
  loader: boolean;
  concurrentLoader: number;
  animation: Orientation;
  selectedBranch: number;
  selectedPermissions: string[];
}

// App wide state
export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
  global: GlobalState;
}
// Initialize global state
export const initialState: GlobalState = {
  loader: false,
  concurrentLoader: 0,
  animation: Orientation.void,
  selectedBranch: null,
  selectedPermissions: [],
};
const reducer = createReducer(
  initialState,

  on(GlobalActions.ChangeAnimation, (state, payload) => {
    return {
      ...state,
      animation: payload.animation,
    };
  }),
  on(GlobalActions.ResetAnimation, (state) => {
    return {
      ...state,
      animation: Orientation.void,
    };
  }),
  on(GlobalActions.AnimateNext, (state) => {
    return {
      ...state,
      animation: Orientation.next,
    };
  }),
  on(GlobalActions.AnimatePrev, (state) => {
    return {
      ...state,
      animation: Orientation.prev,
    };
  }),

  on(GlobalActions.ShowLoader, (state) => {
    return {
      ...state,
      loader: true,
    };
  }),

  on(GlobalActions.HideLoader, (state) => {
    return {
      ...state,
      loader: false,
    };
  }),
  on(GlobalActions.ShowConcurrentLoader, (state) => {
    return {
      ...state,
      concurrentLoader: (state.concurrentLoader || 0) + 1,
    };
  }),

  on(GlobalActions.HideConcurrentLoader, (state) => {
    return {
      ...state,
      concurrentLoader: (state.concurrentLoader || 0) - 1,
    };
  }),

  on(GlobalActions.ChangeLoader, (state, payload) => {
    return {
      ...state,
      loader: payload.loader,
    };
  }),

  on(GlobalActions.setBranch, (state, payload) => {
    return {
      ...state,
      selectedBranch: payload.id,
    };
  }),

  on(GlobalActions.resetSelections, (state) => {
    return {
      ...state,
      selectedBranch: undefined,
    };
  }),

  on(GlobalActions.setUserPermissions, (state, payload) => {
    return {
      ...state,
      selectedPermissions: payload.permissions,
    };
  }),
);

export function globalReducer(state: GlobalState = initialState, action: Action) {
  return reducer(state, action);
}
// App wide reducers
export const appReducer: ActionReducerMap<AppState> = {
  router: routerReducer,
  global: globalReducer,
};
