import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as GlobalActions from '../store/actions/global.actions';
import * as GlobalSelectors from '../store/selectors/global.selectors';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor(private store: Store<any>) {}

  public showLoader() {
    this.store.dispatch(GlobalActions.ShowLoader());
  }

  public hideLoader() {
    this.store.dispatch(GlobalActions.HideLoader());
  }

  public changeLoader(loader: boolean) {
    this.store.dispatch(GlobalActions.ChangeLoader({ loader }));
  }

  public getLoader(): Observable<boolean> {
    return this.store.select(GlobalSelectors.getLoader);
  }
}
