import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { MatIconModule } from '@angular/material/icon';
import { IconRegistryComponent } from '@app/icon-registry/icon-registry.component';

@NgModule({
  declarations: [IconRegistryComponent],
  imports: [HttpClientModule, MatIconModule],
  exports: [IconRegistryComponent],
})
export class IconRegistryModule {}
