/*
 * File: gravel.reducer.ts
 * Project: galilea-centro
 * File Created: Monday, 18th May 2020 6:22:42 pm
 * Author: Kevin Johnson (kjjohnson@uc.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Action, createReducer, on } from '@ngrx/store';
import * as TicketActions from '@actions/gravel.actions';
import { DigitalTicketNew } from '@models/ticket';

export const ticketFeatureKey = 'gravel';

export const initialState: DigitalTicketNew = {
  /* NEW STATE FOR GRAVEL */
  provider: null,
  material: null,
  contractor: null,
  activityType: null,
  activity: null,
  budgetGroup: null,
  budgets: null,
  po: null,
  details: null,
  urbaActivityQuantity: 1,
  ingressInfo: null,
};

const ticketReducer = createReducer(
  initialState,
  on(TicketActions.ResetSelections, () => {
    return initialState;
  }),
  on(TicketActions.SaveProvider, (state, { provider }) => {
    return {
      ...state,
      provider,
    };
  }),
  on(TicketActions.SaveMaterial, (state, { material }) => {
    return {
      ...state,
      material,
    };
  }),
  on(TicketActions.SaveContractor, (state, { contractor }) => {
    return {
      ...state,
      contractor,
    };
  }),
  on(TicketActions.SaveActivityType, (state, { activityType }) => {
    return {
      ...state,
      activityType,
    };
  }),

  on(TicketActions.SaveActivity, (state, { activity }) => {
    return {
      ...state,
      activity: activity,
    };
  }),
  on(TicketActions.SaveBudgetGroup, (state, { budgetGroup }) => {
    return {
      ...state,
      budgetGroup,
    };
  }),
  on(TicketActions.SaveBudgets, (state, { budgets }) => {
    return {
      ...state,
      budgets,
    };
  }),
  on(TicketActions.SavePO, (state, { po }) => {
    return {
      ...state,
      po,
    };
  }),
  on(TicketActions.SaveDetails, (state, { details }) => {
    return {
      ...state,
      details,
    };
  }),
  on(TicketActions.SaveUrbaActivityQuantity, (state, { urbaActivityQuantity }) => {
    return {
      ...state,
      urbaActivityQuantity,
    };
  }),

  on(TicketActions.SaveIngressInfo, (state, { ingressInfo }) => {
    return {
      ...state,
      ingressInfo,
    };
  }),
);

export function reducer(state: DigitalTicketNew = initialState, action: Action) {
  return ticketReducer(state, action);
}
