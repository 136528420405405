export const commonEnvs = {
  IVA: 19,
  invoiceEmail: 'terceros-76245745@dte.iconstruye.com',
  defaultContractRetentionPercentage: 0.05,
  minutesCloseBudgetButtonDisabled: 5,
  decimals: {
    r: 1, // repetitions
    p: 2, // prices at CLP
    pUf: 2, // prices at UF
    q: 4, // quantities
    pq: 0, // prices * quantities
    perc: 0, // percentages
    rInfo: '1.0-1',
    pInfo: '1.0-2',
    pUfInfo: '1.0-2',
    qInfo: '1.0-4',
    pqInfo: '1.0-0',
    percInfo: '1.0-0',
  },
  evaluationObligatoryComment: false,
  priceAgreementChangeTolerance: 0.15,
};
