<mat-card [ngClass]="size === 'double' ? 'double' : 'simple'">
  <p class="title">{{ title }}</p>
  <div class="body">
    <p
      isEllipsisActive
      [matTooltip]="column1"
      [matTooltipShowDelay]="500"
      class="column1"
      [style]="{ width: getColumnWidth(0) }"
    >
      {{ column1 }}
    </p>
    <p
      *ngIf="!message"
      isEllipsisActive
      [matTooltip]="column2"
      [matTooltipShowDelay]="500"
      class="column2"
      [style]="{ width: getColumnWidth(1) }"
    >
      {{ column2 }}
    </p>
    <div *ngIf="message" class="column2 button" [style]="{ width: getColumnWidth(1) }" (click)="click()">
      {{ message }}
      <mat-icon>{{ icon }}</mat-icon>
    </div>
  </div>
</mat-card>
