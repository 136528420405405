/*
 * File: Console.ts
 * Project: galilea-centro
 * File Created: Tuesday, 9th June 2020 11:01:47 am
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { environment } from '@env/environment';

type ConsoleType = {
  log: typeof console.log;
  time: typeof console.time;
  timeEnd: typeof console.timeEnd;
  error: typeof console.error;
  warn: typeof console.warn;
};
export const Console = ((): ConsoleType => {
  if (environment.enableLogger && !environment.production) {
    return console;
  } else {
    return {
      log: () => {},
      time: () => {},
      timeEnd: () => {},
      error: () => {},
      warn: () => {},
    };
  }
})();
