import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractModal } from '@views/reusable/modals/abstract-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorAlert } from '@app/models';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['../abstract-modal.component.scss', './error-modal.component.scss'],
})
export class ErrorModalComponent extends AbstractModal implements OnInit {
  title: string;
  message: string;
  code?: string;

  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ErrorAlert,
  ) {
    super();
  }

  submit(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    if (this.data) {
      this.title = this.data.title;
      this.message = this.data.message;
      this.code = this.data.code;
    }
  }
}
