import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as GlobalActions from '../store/actions/global.actions';
import * as GlobalSelectors from '../store/selectors/global.selectors';
import { Observable } from 'rxjs';
import { Orientation, routeAnimationTime } from '@app/animations';
import { NavigationEnd, Router } from '@angular/router';
import { delay, filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AnimationService {
  constructor(private store: Store, private router: Router) {
    // reset animation state with a slight delay after navigation & animation end
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        delay(routeAnimationTime + 50),
      )
      .subscribe(() => {
        this.resetAnimation();
      });
  }

  public changeAnimation(animation: Orientation) {
    this.store.dispatch(GlobalActions.ChangeAnimation({ animation }));
  }

  public animateNext() {
    this.store.dispatch(GlobalActions.AnimateNext());
  }

  public animatePrevious() {
    this.store.dispatch(GlobalActions.AnimatePrev());
  }

  public resetAnimation() {
    this.store.dispatch(GlobalActions.ResetAnimation());
  }

  public getAnimation(): Observable<Orientation> {
    return this.store.select(GlobalSelectors.getAnimation);
  }
}
