/*
 * File: gravel.selectors.ts
 * Project: galilea-centro
 * File Created: Thursday, 10th October 2019 3:26:19 pm
 * Author: Kevin Johnson (kjjohnson@uc.cl)
 * -----
 * Copyright 2019 - 2019 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ticketFeatureKey } from '@reducers/gravel.reducer';
import { DigitalTicketNew } from '@models/ticket';
import { cloneDeep } from 'lodash';

export const getTicketState = createFeatureSelector<DigitalTicketNew>(ticketFeatureKey);

export const getSelections = createSelector(getTicketState, (state: DigitalTicketNew) => ({
  //TODO try to remove cloneDeep and test
  provider: cloneDeep(state.provider),
  material: cloneDeep(state.material),
  contractor: cloneDeep(state.contractor),
  activityType: cloneDeep(state.activityType),
  activity: cloneDeep(state.activity),
  budgetGroup: cloneDeep(state.budgetGroup),
  budgets: cloneDeep(state.budgets),
  po: cloneDeep(state.po),
  details: cloneDeep(state.details),
  urbaActivityQuantity: state.urbaActivityQuantity,
  ingressInfo: cloneDeep(state.ingressInfo),
}));
