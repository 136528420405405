/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import Fuse from 'fuse.js';
import { cloneDeep } from 'lodash';

interface UpdateParams<T> {
  objects: T[];
  options: Fuse.IFuseOptions<T>;
}
interface SearchParams<T> {
  text: string;
  instance: Fuse<T>;
  enableSingleCharSearch?: boolean;
}
export interface SearchingOutput<T> {
  results: T[];
  searching: boolean;
}

const normalize = (text: string) => text?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

/**
 * Is not possible to use at the same time more than 1 instance of this service, because its an App singleton.
 */
@Injectable({
  providedIn: 'root',
})
export class SearchService<T = any> {
  defaultOptions: Fuse.IFuseOptions<T> = {
    ignoreLocation: true,
    threshold: 0.4,
    minMatchCharLength: 1,
    useExtendedSearch: true,
    isCaseSensitive: false,
    getFn: (obj, path) => {
      const value: string = (Fuse as any).config.getFn(obj, path);
      if (typeof value === 'string') return normalize(value);
      else if (Array.isArray(value)) return (value as any[]).map((el) => (typeof el === 'string' ? normalize(el) : el));
      return value;
    },
  };

  constructor() {}

  /**
   *
   * @param param0 searcher entities or options to be used
   * @returns new Fuse instance
   */
  public updateSearcher({ objects = [], options }: UpdateParams<T>): Fuse<T> {
    if (!options?.keys) throw new Error('Keys are required on searcher options.');
    return new Fuse<T>(cloneDeep(objects), { ...this.defaultOptions, ...options });
  }

  /**
   *
   * @param text string being searched
   * @param instance instance of searcher to be used.
   * @param enableSingleCharSearch allow searching by 1 character.
   * @returns results and boolean indicating an active search
   */
  public search({ text, instance, enableSingleCharSearch }: SearchParams<T>): SearchingOutput<T> {
    // Empty or 1 char length search
    if (!text || (!enableSingleCharSearch && text.trim().length <= 1)) return { results: [], searching: false };
    // Check defined instance
    if (!instance) throw new Error('Searcher not instanciated');
    // Normal search
    return { results: instance.search(normalize(text.trim())).map((x) => x.item), searching: true };
  }
}
