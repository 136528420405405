// eslint-disable-next-line no-shadow
export enum EvaluationType {
  POSITIVE = 'POSITIVE',
  NORMAL = 'NORMAL',
  NEGATIVE = 'NEGATIVE',
}
// eslint-disable-next-line no-shadow
export enum SnackBarStatus {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
}
