import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import packageJson from '../package.json';
// eslint-disable-next-line no-console
console.log(`Version ${packageJson.version}`);
if (environment.production || environment.prerelease) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // waiting for angular to load bootstrapping and then only loading service worker
  .then(() => {
    if ('serviceWorker' in navigator && (environment.production || environment.prerelease)) {
      navigator.serviceWorker.register('/ngsw-worker.js');
    }
  })
  /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
  .catch((err) => console.error(err));
