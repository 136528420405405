import { Router, NavigationEnd, Event, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { uniq } from 'lodash';

import mobileRoutesOrder from '@app/views/mobile/routes-order';
import { ActivityType } from '@models/activity';

const orderByRole = {
  terreno: mobileRoutesOrder,
};

interface View {
  name: string;
  store: string[];
  group?: number;
  optional?: boolean;
  whenNavigating?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  lastRoute = null;
  currentRoute = '/terreno/ticket'; // TODO obtener del router actual
  // Monitor step inside a multi-step component, for displaying the footer navbar on the firt step only
  currentStep = 1;
  subscriptions: Subscription[] = [];

  visitedRoutes: string[] = [];

  desktopPreviousRoute;
  desktopCurrentRoute;

  constructor(private router: Router, private route: ActivatedRoute, private location: Location) {
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.lastRoute = this.currentRoute;
        this.currentRoute = event.url;
        this.currentStep = 1;
      }),
      this.router.events
        .pipe(
          filter((evt: any) => evt instanceof RoutesRecognized),
          pairwise(),
        )
        .subscribe((events: RoutesRecognized[]) => {
          this.desktopPreviousRoute = events[0].urlAfterRedirects;
          this.desktopCurrentRoute = events[1].urlAfterRedirects;
          this.visitedRoutes.splice(0, 0, events[0].urlAfterRedirects);
          if (this.visitedRoutes.length > 5) {
            this.visitedRoutes = this.visitedRoutes.slice(0, 5);
          }
        }),
    );
  }

  watchCurrentRoute(): Observable<Event> {
    return this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
  }

  getLastRoute(): string {
    return this.lastRoute.substring(1);
  }

  getFirstRoute(activityType: ActivityType): string {
    return this.computeNewRoute('first', activityType ?? ActivityType.house, false);
  }

  getPreviousRoute(activityType: ActivityType, skipOptional = true): string {
    return this.computeNewRoute('back', activityType ?? ActivityType.house, skipOptional);
  }

  getNextRoute(activityType: ActivityType, skipOptional = true, routesSequenceStart?: number): string {
    return this.computeNewRoute('next', activityType ?? ActivityType.house, skipOptional, routesSequenceStart);
  }

  getCurrentStep(): number {
    return this.currentStep;
  }
  updateCurrentStep(newStep: number): void {
    this.currentStep = newStep;
  }

  //TODO handle optional views that store optional variables
  getStoreVariablesNames(path: string, activityType: ActivityType): string[] {
    const routeSegments = path.substring(1).split('/');
    const [section, module, stepName] = [...routeSegments];

    // This happens when URL is '/supervisor/ticket' or '/supervisor/aridos'
    // First view should never require any variable on store
    if (!stepName) return [];

    // If activityType is not defined, try with 'HOUSE'
    let routesSequence = orderByRole[section][module][activityType ?? ActivityType.house];
    let currentViewPosition = routesSequence.findIndex((view: View) => view.name === stepName);
    // If failed, try with 'URBA'
    if (currentViewPosition < 0) {
      routesSequence = orderByRole[section][module][activityType ?? ActivityType.urba];
      currentViewPosition = routesSequence.findIndex((view: View) => view.name === stepName);
    }

    const previousViews = routesSequence
      .slice(0, currentViewPosition)
      .filter((view: View) =>
        routesSequence[currentViewPosition].group ? routesSequence[currentViewPosition].group !== view.group : true,
      );
    return uniq(
      previousViews.reduce((acum: string[], view: View) => {
        return [...acum, ...view.store];
      }, []),
    );
  }

  computeNewRoute(
    navDirection: string,
    activityType: ActivityType,
    skipOptional: boolean,
    routesSequenceStart = 0,
  ): string {
    const routeSegments = this.currentRoute.substring(1).split('/');
    const [section, , stepName] = [...routeSegments];
    let [, module] = [...routeSegments];

    // This happens when URL is just '/supervisor'. Assume first route of ticket section
    if (!module) {
      module = 'ticket';
    }

    const routesSequence = orderByRole[section][module][activityType];
    let currentViewPosition: number;

    // This happens when URL is '/supervisor/ticket' or '/supervisor/aridos'
    if (!stepName) {
      currentViewPosition = 0;
    } else {
      currentViewPosition = routesSequence.findIndex((view: View) => view.name === stepName);
    }

    let candidatesRoutes: View[];
    switch (navDirection) {
      case 'back': {
        // Reverse to find first ocurrence
        candidatesRoutes = routesSequence.slice(0, currentViewPosition).reverse();
        break;
      }
      case 'next': {
        candidatesRoutes = routesSequence.slice(currentViewPosition + 1);
        break;
      }
      case 'first': {
        candidatesRoutes = routesSequence;
        break;
      }
    }

    // Find requested route considering optional and direction
    let newView = candidatesRoutes.find((view: View) =>
      skipOptional ? !view.optional : !view.optional || (view.optional && view.whenNavigating === navDirection),
    );

    // When navigating next from the last route or navigating back from first route.
    if (!newView) {
      newView = routesSequence[routesSequenceStart];
    }

    return [section, module, newView.name].join('/');
  }

  goLastRoute(defaultRoute: string): void {
    /**
     * Get the first route that contains the route you want to navigate.
     * Prefer outside module summary routes (tables) with tab number ("?")
     * DefaultRoute usually is the outside module summary routes without tab.
     */
    const nearestMatchingRoutes = this.visitedRoutes.filter((r) => r.includes(defaultRoute));
    const nearestRoute = nearestMatchingRoutes.find((r) => r.includes('?tab')) ?? nearestMatchingRoutes[0];
    this.router.navigateByUrl(nearestRoute || defaultRoute);
  }

  setTabQueryParam(tab: string): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        tab: tab,
      },
      // Preserve the existing query params in the route
      queryParamsHandling: 'merge',
      // Do not trigger navigation
      skipLocationChange: false,
    });
  }

  getTabQueryParam(tabsLength: number): number {
    const tab = parseInt(this.route.snapshot.queryParams?.tab);
    if (!tab || tab > tabsLength - 1) {
      const newTab = 0;
      this.setTabQueryParam(newTab.toString());
      return newTab;
    }
    return tab;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
