<mat-card>
  <p>{{ title }}</p>
  <div class="body">
    <div class="column1">
      <app-number-input
        (onChange)="changeCurrentQuantity($event)"
        [value]="current"
        [interval]="interval"
        [min]="min"
        [max]="max"
        [softMin]="softMin"
        [softMax]="softMax"
        [precision]="precision"
      >
      </app-number-input>
    </div>
    <div class="column2">
      {{ sufixText }}
    </div>
  </div>
</mat-card>
