<!--
File: select-multiple.component.html
Project: galilea-centro
File Created: Wednesday, 21st April 2021 1:04:35 pm
Author: Fabián Ulloa (fabian@inventures.cl)
-----
Last Modified: Fri Mar 18 2022
Modified By: Kevin Johnson
-----
Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
Terms and conditions defined in license.txt
-----
Inventures - www.inventures.cl
-->
<ng-container>
  <div *ngIf="!searchable" class="multi-select-component">
    <mat-form-field appearance="standard">
      <mat-label>{{ title }}</mat-label>
      <mat-select
        [disabled]="disabled"
        (selectionChange)="selectionChange($event.value)"
        [(value)]="currentValue"
        multiple
      >
        <mat-option *ngFor="let type of selections" [value]="type.value">{{ type.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="searchable" class="multi-select-component autocomplete">
    <mat-form-field floatLabel="never">
      <mat-label>{{ title }}</mat-label>
      <mat-select
        [formControl]="selectedOption"
        (selectionChange)="selectionChange($event.value)"
        [(value)]="currentValue"
        multiple
        [placeholder]="placeholder"
        [panelClass]="panelClass"
        [disabled]="disabled"
      >
        <mat-option>
          <ngx-mat-select-search
            [showToggleAllCheckbox]="showToggleAllCheckbox"
            [toggleAllCheckboxChecked]="currentValue.length === filteredSelections?.length"
            [toggleAllCheckboxIndeterminate]="
              currentValue.length > 0 && currentValue.length !== filteredSelections?.length
            "
            (toggleAll)="toggleSelectAll($event)"
            [toggleAllCheckboxTooltipMessage]="toggleAllCheckboxTooltipMessage"
            [toogleAllCheckboxTooltipPosition]="'above'"
            [formControl]="searcherControl"
            [placeholderLabel]="'Buscar'"
            [noEntriesFoundLabel]="'Sin resultados'"
            [clearSearchInput]="true"
          ></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let type of filteredSelections" [value]="type.value" [disabled]="type.disabled">
          {{ type.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</ng-container>
