/*
 * File: global.selectors.ts
 * Project: galilea-centro
 * File Created: Thursday, 10th October 2019 11:55:57 am
 * Author: Hector Piñero (hector@inventures.cl)
 * -----
 * Copyright 2019 - 2019 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { globalFeatureKey, GlobalState } from '../reducers/index';
import { cloneDeep } from 'lodash';
export const getGlobalState = createFeatureSelector<GlobalState>(globalFeatureKey);
export const getLoader = createSelector(getGlobalState, (state: GlobalState) => cloneDeep(state.loader));
export const getConcurrentLoader = createSelector(getGlobalState, (state: GlobalState) => state.concurrentLoader);

export const getAnimation = createSelector(getGlobalState, (state: GlobalState) => cloneDeep(state.animation));

export const getBranch = createSelector(getGlobalState, (state: GlobalState) => cloneDeep(state.selectedBranch));

export const getUserPermissions = createSelector(getGlobalState, (state: GlobalState) =>
  cloneDeep(state.selectedPermissions),
);
