/*
 * File: loader.service copy.ts
 * Project: galilea-centro
 * File Created: Thursday, 23rd January 2020 12:41:09 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Injectable } from '@angular/core';
import { ErrorModalComponent } from '@app/views/reusable/modals/error-modal/error-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorCode, ErrorAlert } from '@app/models';

const DATA = {
  FORBIDDEN: {
    title: 'Error de permisos',
    message: 'Tu usuario no tiene permiso para realizar esta acción',
    showCode: true,
  },
  BAD_USER_INPUT: {
    title: 'Error de validación',
    message: 'Hubo un error al validar la solicitud',
    showCode: true,
  },
  GRAPHQL_VALIDATION_FAILED: {
    title: 'Error de validación',
    message: 'Hubo un error al validar la solicitud',
    showCode: false,
  },
  UNAUTHENTICATED: {
    title: 'Error de autenticación',
    message: 'Tu sesión expiró. Por favor recarga la página',
    showCode: false,
  },
} as Record<ErrorCode, ErrorAlert & { showCode: boolean }>;

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private dialogRef: MatDialogRef<ErrorModalComponent>;

  constructor(public dialog: MatDialog) {}

  public showError(errorCode: ErrorCode, internalErrorMessage: string): void {
    const data = errorCode ? DATA[errorCode] : null;
    if (data?.showCode) {
      data.code = internalErrorMessage || '';
      delete data.showCode;
    }
    this.dialogRef = this.dialog.open(ErrorModalComponent, {
      data,
    });
  }

  public hideError(): void {
    this.dialogRef.close();
  }
}
