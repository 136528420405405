import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CardSize } from '../form-card/form-card.component';

@Component({
  selector: 'app-two-column-button-card',
  templateUrl: './two-column-button-card.component.html',
  styleUrls: ['./two-column-button-card.component.scss'],
})
export class TwoColumnButtonCardComponent implements OnInit {
  @Input() attr: string;
  @Input() title: string;
  @Input() buttonText: string;
  @Input() disableButton = false;
  @Input() icon = '';
  @Input() column: string;
  @Input() size: CardSize = 'simple';
  @Output() selected: EventEmitter<{ attr: string }> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  click(): void {
    this.selected.emit({ attr: this.attr });
  }
}
