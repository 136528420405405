/*
 * File: niceDate.pipe.ts
 * Project: galilea-centro
 * File Created: Tuesday, 5th May 2020 10:09:34 am
 * Author: Mario Merino (mario@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

interface NiceDatePipeOptions {
  ignoreHour?: boolean;
  ignoreDayName?: boolean;
}

@Pipe({
  name: 'niceDate',
})
export class NiceDatePipe implements PipeTransform {
  transform(value: string, options?: NiceDatePipeOptions): string {
    if (!value) return '';
    // Transform string input in date in miliseconds
    const valueMiliSeconds = Date.parse(value);

    // Initialise date pipe base of angular - chage with locale chile
    const datePipe = new DatePipe('es-CL');

    // Set the date for today, yesterday and tomorrow at 0:00:00,
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);

    const oneDay = 24 * 60 * 60 * 1000;
    const yesterday = today.getTime() - oneDay;
    const tomorrow = today.getTime() + oneDay;

    if (valueMiliSeconds >= tomorrow || valueMiliSeconds < yesterday) {
      const format = `${options?.ignoreDayName ? '' : 'EE '}d/MM/yy${options?.ignoreHour ? '' : ', HH:mm'}`;
      const x = datePipe.transform(value, format);
      // fix to get day of the week starting in caps
      return x ? x.charAt(0).toUpperCase() + x.slice(1) : null;
    } else if (valueMiliSeconds >= yesterday && valueMiliSeconds < today.getTime()) {
      return options?.ignoreHour ? 'Ayer' : `Ayer, ${datePipe.transform(value, 'HH:mm')}`;
    } else {
      return options?.ignoreHour ? 'Hoy' : `Hoy, ${datePipe.transform(value, 'HH:mm')}`;
    }
  }
}
