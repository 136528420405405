import { User } from './user';

export interface ActivityCategory {
  id: number;
  name: string;
  type: ActivityCategoryType;
  enabled: boolean;
  createdBy: User;
  createdAt: string;
  updatedBy: User;
  updatedAt: string;
}

// eslint-disable-next-line no-shadow
export enum ActivityCategoryType {
  HOUSE = 'HOUSE',
  URBANIZATION = 'URBANIZATION',
  OTHER = 'OTHER',
  BUILDING = 'BUILDING',
  HOUSE_REPAIR = 'HOUSE_REPAIR',
  URBANIZATION_REPAIR = 'URBANIZATION_REPAIR',
  OTHER_REPAIR = 'OTHER_REPAIR',
  BUILDING_REPAIR = 'BUILDING_REPAIR',
  SERVICE = 'SERVICE',
  LABOR = 'LABOR',
}
