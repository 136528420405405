import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpProgressEvent } from '@angular/common/http';
import { GraphqlService } from './graphql.service';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { NgxImageCompressService } from 'ngx-image-compress';
interface FileInput {
  fileName: string;
  type: string;
  uploadPath: string;
}
@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private readonly http: HttpClient,
    private graphql: GraphqlService,
    private imageCompress: NgxImageCompressService,
  ) {}
  basicUpload(files: FileList, url: string) {
    const formData = new FormData();
    Array.from(files).forEach((f) => formData.append('file', f));

    return this.http.post(url, formData);
  }
  uploadAndProgress(files: FileList, url: string): Observable<HttpEvent<unknown>> {
    const formData = new FormData();
    Array.from(files).forEach((f) => formData.append(`file`, f));
    return this.http.put(url, files.item(0), {
      reportProgress: true,
      observe: 'events',
      headers: {
        'Content-Type': files.item(0).type,
      },
    });
  }

  async uploadCompressAndProgress(
    files: FileList,
    base64File: string,
    url: string,
  ): Promise<Observable<HttpEvent<unknown>>> {
    if (files.item(0).type.includes('video')) return this.uploadAndProgress(files, url);
    const orientation = await this.imageCompress.getOrientation(files.item(0));
    //The ratio is used to reduce the width and height of the image percentually
    //Ex: ratio = 50 => newWidth = oldWidth * 0.50
    const ratio = 75;
    const imageQuality = 50;
    const compressedBase64File = await this.imageCompress.compressFile(base64File, orientation, ratio, imageQuality);
    const compressedFile = await this.base64ToFile(compressedBase64File, files.item(0).name, files.item(0).type);

    return this.http.put(url, compressedFile, {
      reportProgress: true,
      observe: 'events',
      headers: {
        'Content-Type': files.item(0).type,
      },
    });
  }

  base64ToFile(base64: string, filename: string, mimeType: string): Promise<File> {
    return fetch(base64)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  calcProgressPercent(event: HttpProgressEvent) {
    return Math.round((100 * event.loaded) / event.total);
  }

  getPresignedUrl({ uploadPath, fileName, type }: FileInput) {
    return this.graphql.lazyQuery(
      gql`
        query getSignedUrl($uploadPath: String!, $fileName: String!, $type: String!) {
          getSignedUrl(uploadPath: $uploadPath, fileName: $fileName, type: $type) {
            url
            uploadName
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
        variables: {
          uploadPath,
          fileName,
          type,
        },
      },
    );
  }
}
