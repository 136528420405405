/*
 * File: budget
 * Project: galilea-centro
 * File Created: Monday, 7th February 2022 3:23:05 pm
 * Author: Joaquín Muñoz (joaquin@inventures.cl)
 * -----
 * Last Modified: Wed Jun 15 2022
 * Modified By: Kevin Johnson
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { map, uniq } from 'lodash';
import { Budget } from '../models/budget';

export const parseBudgetNames = (budgets: Budget[]) => {
  const uniqueBudgetNames = uniq(map(budgets, 'name'));

  // Handle the Non-House case when there's only 1 budget
  if (uniqueBudgetNames.length === 1) {
    return uniqueBudgetNames[0];
  } else {
    const names = uniqueBudgetNames.sort((a: string, b: string) =>
      new Intl.Collator('es', { numeric: true }).compare(a, b),
    );
    return `Lotes ${names
      .filter((name) => !/Bodega/.test(name))
      .join(', ')
      .replace(/Lote /g, '')}`;
  }
};
