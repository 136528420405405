import { GetMaterialProposedDetail, DocumentMaterialMovements } from './oc';
import { Warehouse } from './warehouse';
import { Provider } from './provider';
import { Material, DocumentedMaterialMovement } from './materialItems';
import { User } from './user';
import { Activity } from './activity';
import { Budget } from './budget';
import { PurchaseOrderDetailType } from './ingress';
import { TributaryDocument } from './tributaryDocument';
import { BudgetGroup } from './budgetGroup';
import { MaterialPriceAgreement } from './priceAgreement';

/**
 * Enums
 */
// eslint-disable-next-line no-shadow
export enum PurchaseOrderAutoApproveEntryType {
  //Entries due to mismanagement of the warehouse, e.g: stock adjustments
  MISMANAGEMENT = 'MISMANAGEMENT',
  //Entries due to selected provider not being one in agreement for the material
  PROVIDER = 'PROVIDER',
  //Entries due to price criteria
  PRICE = 'PRICE',
  //Entries due to service criteria
  SERVICE = 'SERVICE',
  //Using a delivery format without price agreement when there are other formats in agreement
  AGREEMENT_PRICE_FORMAT = 'AGREEMENT_PRICE_FORMAT',
}

// eslint-disable-next-line no-shadow
export enum PurchaseOrderDeliveryType {
  //Default, no delivery type selected
  NONE = 'NONE',
  //Provider dispatchs the goods to the warehouse
  WAREHOUSE_DISPATCH = 'WAREHOUSE_DISPATCH',
  //Warehouse sends someone to pick-up the goods to the provider facilities
  PROVIDER_PICKUP = 'PROVIDER_PICKUP',
  //OFFICE
  OFFICE = 'OFFICE',
  //For materials that are unloaded directly at the construction site
  UNLOAD_TRUCK_CONSTRUCTION = 'UNLOAD_TRUCK_CONSTRUCTION',
}

export const spanishPurchaseOrderDeliveryType = (type: PurchaseOrderDeliveryType): string => {
  switch (type) {
    case PurchaseOrderDeliveryType.WAREHOUSE_DISPATCH:
      return 'Puesto a piso';
    case PurchaseOrderDeliveryType.PROVIDER_PICKUP:
      return 'Retiro en proveedor';
    case PurchaseOrderDeliveryType.NONE:
      return 'No aplica';
    case PurchaseOrderDeliveryType.OFFICE:
      return 'Oficina';
    case PurchaseOrderDeliveryType.UNLOAD_TRUCK_CONSTRUCTION:
      return 'Rampa camión';
  }
};

// eslint-disable-next-line no-shadow
export enum ProviderPaymentPeriod {
  //Payment at delivery or before"
  CONTADO = 'CONTADO',
  //5 days since receiving the invoice
  CINCO_DIAS = 'CINCO_DIAS',
  //15 days since receiving the invoice
  QUINCE_DIAS = 'QUINCE_DIAS',
  //30 days since receiving the invoice
  TREINTA_DIAS = 'TREINTA_DIAS',
  //45 days since receiving the invoice
  CUARENTAYCINTO_DIAS = 'CUARENTAYCINTO_DIAS',
  //60 days since receiving the invoice
  SESENTA_DIAS = 'SESENTA_DIAS',
  //120 days since re = 'CONTADO',ceiving the invoice
  CIENTOVEINTE_DIAS = 'CIENTOVEINTE_DIAS',
}

// eslint-disable-next-line no-shadow
export enum ProviderPaymentMethod {
  //Cash"
  EFECTIVO = 'EFECTIVO',
  //Wire transfer"
  TRANSFERENCIA_ELECTRONICA = 'EFECTIVO',
  //Nominative check
  CHEQUE = 'EFECTIVO',
  //Bank note
  VALE_VISTA = 'EFECTIVO',
}

// eslint-disable-next-line no-shadow
export enum PurchaseOrderStatus {
  DRAFT = 'DRAFT',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  SENT_PROVIDER = 'SENT_PROVIDER',
  RECEIVING_MATERIALS = 'RECEIVING_MATERIALS',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
  CLOSED = 'CLOSED',
}

// eslint-disable-next-line no-shadow
export enum PurchaseOrderType {
  MATERIAL = 'MATERIAL',
  SERVICE = 'SERVICE',
  SERVICE_ACCOUNTING = 'SERVICE_ACCOUNTING',
  LABOR = 'LABOR',
}

/**
 * Interfaces
 */
export interface PackagingFormat {
  id: number;
  name: string;
  conversion: number;
  stepSize: number;
  // Precision to round the quantity being purchased
  purchasePrecision: number;
  createdAt: string;
  updatedAt: string;
}

export interface EstimatedTimeOfArrival {
  estimatedDate: string;
  percentage: number;
}

export interface PurchaseOrderDetail {
  id: number;
  purchaseOrder: PurchaseOrder;
  material: Material;
  deliveryFormat: PackagingFormat;
  budget: Budget;
  activity: Activity;
  quantity: number;
  comments: string;
  type: PurchaseOrderDetailType;
  unitPrice: number;
  currency: string;
  providerSKU: string;
  additionalTax: number;
  vatPercentage: number;
  finalAmount: number;
  priceAgreements: MaterialPriceAgreement[];
  createdAt: string;
  updatedAt: string;
  budgetPrice: number;
  currentBudgetPrice: number;
  toBePurchased: number;
  overrunHistoric: number;
  overrunPurchased: number;
  order: number;
  receivedQuantity: number;
  paidQuantity: number;
}

export interface PurchaseOrderGroupedDetail {
  activity: Partial<Activity>;
  quantity: number;
  quantityConverted?: number;
  purchaseOrderDetails?: PurchaseOrderDetail[];
  repetitions?: number;
  unitQuantity?: number;
  totalQuantity?: number;
  enabledMaterialQuantity?: number;
  purchasedQuantity?: number;
  deliveredQuantity?: number;
  toBePurchased?: number;
  budgetedQuantity?: number;
  overrunPurchased?: number;
  totalOverrun?: number;
}

export interface PurchaseOrderMaterialBudget {
  budgetGroup: BudgetGroup;
  activities: PurchaseOrderGroupedDetail[];
}

export interface PendingToEnableByMaterialBudgetGroup {
  budgetGroup: BudgetGroup;
  activities: {
    activity: Activity;
    repetitions: number;
    unitQuantity: number;
    budgetedQuantity: number;
    enabledMaterialQuantity: number;
    pendingToEnableQuantity: number;
  }[];
}

export interface PurchaseOrderMaterial {
  material: Material;
  deliveryFormat: PackagingFormat;
  sku: string;
  budgetPrice: number;
  unitPrice: number;
  currency: string;
  enabledBudgets: [PurchaseOrderMaterialBudget];
  enabledBudgetsQuantity: number;
  overrunReposition: [PurchaseOrderMaterialBudget];
  overrunRepositionQuantity: number;
  adjustmentRepositionQuantity: number;
  securityStocks: [PurchaseOrderMaterialBudget];
  securityStockQuantity: number;
  budgetOverrun: [PurchaseOrderMaterialBudget];
  budgetOverrunQuantity: number;
}
export interface PurchaseOrderAutoApproveEntry {
  type: PurchaseOrderAutoApproveEntryType;
  materialId: number;
  budgetId: number;
  activityId: number;
  unitPrice: number;
  agreementPrice: number;
  maxAgreementPrice: number;
  budgetPrice: number;
  maxBudgetPrice: number;
  requestedQuantity: number;
  agreementProviderIds: number[];
  formatId: number;
  agreementFormatIds: number[];
}
export interface PurchaseOrder {
  id: number;
  sheet: string;
  status: PurchaseOrderStatus;
  provider: Partial<Provider>;
  authorizedBy: User;
  sentEmails: boolean;
  warehouse: Partial<Warehouse>;
  estimatedTimeOfArrival: EstimatedTimeOfArrival[];
  comments: string;
  editable: boolean;
  sentAt: string;
  authorizedAt: string;
  paymentMethod: ProviderPaymentMethod;
  paymentPeriod: ProviderPaymentPeriod;
  details: PurchaseOrderDetail[];
  netAmount: number;
  taxAmount: number;
  finalAmount: number;
  uuid: string;
  shipToAddress: string;
  shipToURL: string;
  attachmentURL: string;
  additionalTaxAmount: number;
  vatAmount: number;
  createdAt: string;
  updatedAt: string;
  deliveryType: PurchaseOrderDeliveryType;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  shippingResponsibleName: string;
  shippingResponsibleRut: string;
  createdBy: User;
  updatedBy: User;
  expiryDate: string;
  autoApproveMessages: PurchaseOrderAutoApproveEntry[];
  documentedMaterialMovements: DocumentedMaterialMovement[];
  expiredAt: string;
  closedAt: string;
  requestedAt: string;
  expiredBy: User;
  requestedBy: User;
  tributaryDocuments: Partial<TributaryDocument>[];
  materials: Material[];
  materialNames: string[];
  internalComment: string;
  internalAttachmentURLs: string[];
  type: PurchaseOrderType;
  groupedDetails: PurchaseOrderMaterial[];
}

export interface PurchaseOrderByMaterial {
  purchaseOrder: PurchaseOrder;
  draft: number;
  toBeReleased: number;
  toBeSent: number;
  toBeReceived: number;
  receivedQuantity: number;
}

export function translatePurchaseOrderType(s: string): PurchaseOrderType {
  switch (s?.toUpperCase()) {
    case 'MATERIAL':
      return PurchaseOrderType.MATERIAL;
    case 'SERVICE':
      return PurchaseOrderType.SERVICE;
    case 'SERVICE_ACCOUNTING':
      return PurchaseOrderType.SERVICE_ACCOUNTING;
    case 'LABOR':
      return PurchaseOrderType.LABOR;
    default:
      return null;
  }
}

export function translatePurchaseOrderStatus(s: string): PurchaseOrderStatus {
  switch (s?.toUpperCase()) {
    case 'DRAFT':
      return PurchaseOrderStatus.DRAFT;
    case 'PENDING_APPROVAL':
      return PurchaseOrderStatus.PENDING_APPROVAL;
    case 'APPROVED':
      return PurchaseOrderStatus.APPROVED;
    case 'SENT_PROVIDER':
      return PurchaseOrderStatus.SENT_PROVIDER;
    case 'RECEIVING_MATERIALS':
      return PurchaseOrderStatus.RECEIVING_MATERIALS;
    case 'EXPIRED':
      return PurchaseOrderStatus.EXPIRED;
    case 'REJECTED':
      return PurchaseOrderStatus.REJECTED;
    case 'CLOSED':
      return PurchaseOrderStatus.CLOSED;
    default:
      return null;
  }
}

export function processPurchaseOrderStatus(status: PurchaseOrderStatus): string {
  switch (status) {
    case PurchaseOrderStatus.DRAFT:
      return 'Borrador';
    case PurchaseOrderStatus.PENDING_APPROVAL:
      return 'Pendiente de aprobación';
    case PurchaseOrderStatus.APPROVED:
      return 'Aceptado';
    case PurchaseOrderStatus.SENT_PROVIDER:
      return 'Enviado a proveedor';
    case PurchaseOrderStatus.CLOSED:
      return 'Finalizado';
    case PurchaseOrderStatus.REJECTED:
      return 'Rechazado';
    case PurchaseOrderStatus.EXPIRED:
      return 'Expirado';
    case PurchaseOrderStatus.RECEIVING_MATERIALS:
      return 'Esperando despacho';

    default:
      return status;
  }
}

export function translatePurchaseOrderDeliveryType(s: string): PurchaseOrderDeliveryType {
  switch (s?.toUpperCase()) {
    case 'WAREHOUSE_DISPATCH':
      return PurchaseOrderDeliveryType.WAREHOUSE_DISPATCH;
    case 'PROVIDER_PICKUP':
      return PurchaseOrderDeliveryType.PROVIDER_PICKUP;
    case 'NONE':
      return PurchaseOrderDeliveryType.NONE;
    case 'OFFICE':
      return PurchaseOrderDeliveryType.OFFICE;
    case 'UNLOAD_TRUCK_CONSTRUCTION':
      return PurchaseOrderDeliveryType.UNLOAD_TRUCK_CONSTRUCTION;
    default:
      return PurchaseOrderDeliveryType.NONE;
  }
}

/////////////////////////////////OLD//////////////////////////////////////
export interface EditableOC {
  deliveryType?: string;
  contactName?: string;
  contactPhone?: string;
  responsible?: string;
  responsibleRUT?: string;
  status?: PurchaseOrderStatus;
  providerId?: number;
  provider?: {
    legalName: string;
    rut: string;
  };
  estimatedTimeOfArrival?: {
    estimatedDate: Date;
    percentage: number;
  }[];
  warehouse?: Warehouse;
  paymentMethod?: string; //CREDITO
  paymentMethodPeriod?: string; //TREINTA_DIAS
  details?: GetMaterialProposedDetail[];
  groupedDetails?: PurchaseOrderMaterial[];
  attachmentURL?: string;
  comments?: string;
  id?: string;
  type?: PurchaseOrderType;
  shippingResponsibleName?: string;
  contactEmail?: string;
  shippingResponsibleRut?: string;
  shipToAddress?: string;
  sheet?: string;
  uuid?: string;
  documentedMaterialMovements?: DocumentMaterialMovements[];
  internalComment?: string;
  vatExempt?: 'Si' | 'No';
  providerComment?: string;
  createdAt?: string;
  requestedBy?: {
    name: string;
  };
  authorizedAt?: string;
  authorizedBy?: {
    name: string;
  };
  sentAt?: string;
  expiryDate?: string;
}

// eslint-disable-next-line no-shadow
export enum PurchaseOrderByMaterialStatus {
  DRAFT = 'draft',
  TOBERELEASED = 'toBeReleased',
  TOBESENT = 'toBeSent',
  TOBERECEIVED = 'toBeReceived',
  RECEIVEDQUANTITY = 'receivedQuantity',
}
