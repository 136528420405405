import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-two-column-number-card',
  templateUrl: './two-column-number-card.component.html',
  styleUrls: ['./two-column-number-card.component.scss'],
})
export class TwoColumnNumberCardComponent {
  @Input() attr: string;
  @Input() title: string;
  @Output() selected: EventEmitter<{ attr: string; value: number }> = new EventEmitter();
  @Input() sufixText: string;
  @Input() current: number;
  @Input() interval: number;
  @Input() min?: number;
  @Input() max?: number;
  @Input() softMin?: number;
  @Input() softMax?: number;
  @Input() precision?: number;

  constructor() {}

  changeCurrentQuantity({ value }): void {
    this.current = value;
    this.selected.emit({ value, attr: this.attr });
  }
}
