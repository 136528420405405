/* tslint:disable */
import { Injectable } from '@angular/core';

import { isSupported, Messaging, getMessaging, getToken, onMessage } from '@angular/fire/messaging';

import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { GraphqlService } from './graphql.service';
import { LoggerService } from './logger.service';
import { MaterialService } from './material.service';
import { OcService } from './oc.service';
@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  messaging: Messaging;
  isMessagingEnabled = isSupported();

  currentMessage = new BehaviorSubject(null);

  constructor(
    private apollo: GraphqlService,
    private logger: LoggerService,
    private materialService: MaterialService,
    private ocService: OcService,
  ) {
    this.logger.consolelog(`messaging enabled ${this.isMessagingEnabled}`);
    if (this.isMessagingEnabled) {
      this.messaging = getMessaging();
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateToken(token) {
    /* send token to database */
    return this.apollo
      .mutation(
        `
    addToken($token: String!) {
      addToken(token: $token) {
        id
      }
    }
    `,
        { variables: { token } },
      )
      .toPromise();
  }

  async removeToken(): Promise<void> {
    try {
      if (!this.isMessagingEnabled) return;
      const token = await getToken(this.messaging);
      if (!token) return;
      await this.apollo
        .mutation(
          `
        removeToken($token: String!) {
          removeToken(token: $token) {
            id
          }
        }
      `,
          {
            variables: {
              token,
            },
          },
        )
        .toPromise();
    } catch (e) {
      return;
    }
  }

  getPermission(): void {
    if (this.isMessagingEnabled) {
      getToken(this.messaging)
        .then((token) => {
          this.logger.consolelog(token);
          this.updateToken(token);
          this.receiveMessage();
        })
        .catch((err) => {
          this.logger.consolelog('Unable to get permission to notify.', err);
        });
    }
  }

  receiveMessage(): void {
    this.logger.consolelog('start receiving messages');
    onMessage(this.messaging, (payload) => {
      this.logger.consolelog('Message received. ', payload);
      this.currentMessage.next(payload);
      if (payload.data) {
        // Data message, interpret command only if known
        this.processDataMessage(payload.data);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  processDataMessage(data: any): void {
    // Process things
    if (!data.command) {
      return;
    }
    switch (data.command) {
      case 'REFRESH_MATERIAL_OPTIONS': {
        this.logger.consolelog('Received refresh materials');
        this.materialService
          .forceMaterialOptions()
          .pipe(take(1))
          .subscribe((response) => response)
          .unsubscribe();
      }
      case 'REFRESH_SERVICE_ACTIVITIES': {
        this.logger.consolelog('Received refresh service activities');
        this.ocService
          .forceServicePurchaseOrderQuery()
          .valueChanges.pipe(take(1))
          .subscribe((response) => response)
          .unsubscribe();
      }
    }
  }
}
