/*
 * File: roundUp.ts
 * Project: galilea-centro
 * File Created: Monday, 7th February 2022 3:15:46 pm
 * Author: Joaquín Muñoz (joaquin@inventures.cl)
 * -----
 * Last Modified: Monday, 7th February 2022 3:16:01 pm
 * Modified By: Joaquín Muñoz (joaquin@inventures.cl)
 * -----
 * Copyright 2019 - 2022 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
export const roundUp = (n: number, decimal = 2): number => Math.round(n * 10 ** decimal) / 10 ** decimal;
