<mat-card>
  <p>{{ title }}</p>
  <div>
    <app-multi-select
      [selections]="selections"
      (selected)="selectionChange($event.selection)"
      searchable="true"
      [currentValue]="currentValue"
      [placeholder]="''"
      [disabled]="disabled"
      [displayResultsEmptySearch]="true"
      [showToggleAllCheckbox]="true"
    ></app-multi-select>
  </div>
</mat-card>
