import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root',
})
export class SentryScopeService {
  public readonly transactionId: string;

  constructor() {
    const myId = Math.random().toString(36).substr(2, 9);
    this.transactionId = myId;
    Sentry.configureScope(function (scope) {
      scope.setTag('transaction_id', myId);
    });
  }
}
