import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { Selection } from '../select/select.component';

@Component({
  selector: 'app-one-column-dropdown-multiselect-card',
  templateUrl: './one-column-dropdown-multiselect-card.component.html',
  styleUrls: ['./one-column-dropdown-multiselect-card.component.scss'],
})
export class OneColumnDropdownMultiselectCardComponent implements OnInit {
  @Input() selections: Selection[];
  @Input() attr: string;
  @Input() title: string;
  @Input() currentValue: string;
  @Input() disabled?: boolean;
  @Input() searchable?: boolean;
  @Output() selected: EventEmitter<{ selection: Selection['value'][]; attr: string }> = new EventEmitter();
  // currentValue = "";

  constructor() {}

  ngOnInit(): void {
    if (!this.currentValue) {
      this.currentValue = '';
    }
  }
  selectionChange(selection: Selection['value'][]) {
    this.selected.emit({ selection, attr: this.attr });
  }
  displayFn(value?: number) {
    const selection = this.selections.filter((s) => String(s.value) === String(value))[0];
    return selection ? selection.name : undefined;
  }
}
