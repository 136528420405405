import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { interval, Subscription, BehaviorSubject } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { max, min, round } from 'lodash';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements OnInit {
  @ViewChild('input') inputRef: ElementRef;

  /**
   * The quantity to be manipulated.
   * Initially receive and set this value, then call onChange on every update
   *
   * @type {number}
   * @memberof NumberInputComponent
   */
  @Input() value: number;

  /**
   * The max value.
   * The input never going to have values greater than this
   *
   * @type {number}
   * @memberof NumberInputComponent
   */
  @Input() max: number;

  /**
   * Soft max value.
   * If the input is greater than this value, will show the warning message
   *
   * @type {number}
   * @memberof NumberInputComponent
   */
  @Input() softMax: number;

  /**
   *
   * The min value.
   * The input never going to have values lower than this
   *
   * @type {number}
   * @memberof NumberInputComponent
   */
  @Input() min: number;

  /**
   * Soft min value.
   * If the input is lower than this value, will show the warning message
   *
   * @type {number}
   * @memberof NumberInputComponent
   */
  @Input() softMin: number;

  /**
   * Interval to increment with the buttons
   *
   * @memberof NumberInputComponent
   */
  @Input() interval = 1;

  /**
   * Warning message to show if any soft condition is breaks
   *
   * @memberof NumberInputComponent
   */
  @Input() warningMessage = '';

  /**
   * Interval time to increment the value in long press
   * Time in ms
   * @memberof NumberInputComponent
   */
  @Input() intervalTime = 200;

  /**
   * Function to process the value before send.
   * Useful to round numbers or to limit decimals amount
   */
  @Input() processValue = (n) => n;

  @Input() editable = true;

  /**
   * Precision of the values format and decimals for the max and min round.
   * If the value is over (max - 10^precision), then the max button is disabled
   *
   * @type {number}
   * @memberof NumberInputComponent
   */
  @Input() precision = 2;

  @Output() onChange: EventEmitter<{
    value: number;
    wasButton: boolean;
  }> = new EventEmitter();
  intervalSubscription: Subscription = null;

  current$: BehaviorSubject<string>;
  pipeFormat = '1.0-2';
  ignoreDebouncedPipeChange = true;

  constructor(private numberPipe: DecimalPipe) {}

  ngOnInit(): void {
    this.pipeFormat = `1.0-${this.precision ?? 2}`;
    this.current$ = new BehaviorSubject<string>(String(this.value));
    this.current$.pipe(debounceTime(800), distinctUntilChanged()).subscribe((value: string) => {
      if (this.ignoreDebouncedPipeChange) {
        this.ignoreDebouncedPipeChange = false;
        return;
      }
      this.handleChange(value, false);
    });
    this.interval = this.interval ?? 1;
    this.precision = this.precision ?? 2;
  }

  updateInputValue(value: number | string): void {
    this.inputRef.nativeElement.value = value;
  }

  handleChangeDebounced(value: string): void {
    this.current$.next(value);
  }

  parseSpanishNumber(value: string): number {
    return Number(value.replace(/\./g, '').replace(/\,/g, '.'));
  }

  handleChange(value: string, wasButton: boolean): void {
    // Parse spanish number
    const parsed = wasButton ? Number(value) : this.parseSpanishNumber(value);
    if (isNaN(parsed)) return;
    const rounded = round(parsed, this.precision ?? 2);
    const newValue = this.limitUnderMax(this.limitAboveMin(rounded));
    this.updateAndEmit(newValue, wasButton);
  }

  /**
   * Emit event change and force set the value in the input
   */
  updateAndEmit(value: number, wasButton: boolean): void {
    const newValue = this.processValue(value);
    this.onChange.emit({ value: newValue, wasButton });
    this.updateInputValue(this.numberPipe.transform(newValue, this.pipeFormat));
  }

  /**
   * Show warning box and message
   */
  isWarning(): boolean {
    return (
      // Value greater than soft max
      (Number.isFinite(this.softMax) && this.value > this.softMax) ||
      // Value lower than soft min
      (Number.isFinite(this.softMin) && this.value < this.softMin)
    );
  }

  /**
   * Handle Increments with button
   */
  handleButtonChange(newValue: number): void {
    // Set ignoreDebouncedPipeChange on true to ignore the observable change, but update it anyways.
    this.ignoreDebouncedPipeChange = true;
    this.current$.next(String(newValue));
    // Manually trigger the change function.
    this.handleChange(String(newValue), true);
  }

  onIncrementButtonClick(): void {
    this.handleButtonChange(this.value + this.interval);
  }
  onDecrementButtonClick(): void {
    this.handleButtonChange(this.value - this.interval);
  }

  /**
   *  Handle long press
   */
  incrementInterval(): void {
    this.stopInterval();
    if (this.isAddButtonDisabled()) return;
    this.intervalSubscription = interval(this.intervalTime).subscribe((iter) => {
      const nextValue = this.limitUnderMax(this.value + this.interval * 2 ** iter);
      this.handleButtonChange(nextValue);
      if (nextValue === this.max) this.stopInterval();
    });
  }
  decrementInterval(): void {
    this.stopInterval();
    if (this.isRemoveButtonDisabled()) return;
    this.intervalSubscription = interval(this.intervalTime).subscribe((iter) => {
      const nextValue = this.limitAboveMin(this.value - this.interval * 2 ** iter);
      this.handleButtonChange(nextValue);
      if (nextValue === this.min) this.stopInterval();
    });
  }
  stopInterval(): void {
    if (this.intervalSubscription && !this.intervalSubscription.closed) {
      this.intervalSubscription.unsubscribe();
      this.inputRef.nativeElement.value = this.value;
    }
  }

  limitUnderMax(value: number): number {
    return Number.isFinite(this.max) ? min([value, this.max]) : value;
  }
  limitAboveMin(value: number): number {
    return Number.isFinite(this.min) ? max([this.min, value]) : value;
  }

  isValueOverMax(value: number): boolean {
    return Number.isFinite(this.max) && value >= this.max - 10 ** -this.precision;
  }
  isValueUnderMin(value: number): boolean {
    return Number.isFinite(this.min) && value <= this.min + 10 ** -this.precision;
  }

  isAddButtonDisabled(): boolean {
    return this.isValueOverMax(this.value);
  }
  isRemoveButtonDisabled(): boolean {
    return this.isValueUnderMin(this.value);
  }
}
