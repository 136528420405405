/*
 * File: gravel.service.ts
 * Project: galilea-centro
 * File Created: Wednesday, 9th October 2019 3:26:24 pm
 * Author: Hector Piñero (hector@inventures.cl)
 * -----
 * Copyright 2019 - 2019 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { uniq } from 'lodash';

import { DigitalTicketNew, IssueTicketDetail as Detail } from '@models/ticket';
import { PurchaseOrder as PO, Detail as PODetail } from '@models/oc';
import { ActivityType, ActivityForTicket } from '@models/activity';
import { IngressInfo } from '@models/ingress';
import { SimpleContractor } from '@models/contractor';
import { BudgetGroup } from '@models/budgetGroup';
import { Material } from '@models/materialItems';
import { Provider } from '@models/provider';
import { Budget } from '@app/models/budget';

import { FunctionService } from './function.service';

import * as TicketActions from '../store/actions/gravel.actions';
import * as TicketSelectors from '../store/selectors/gravel.selectors';
import { TributaryDocumentType, processTributaryDocumentType } from '@app/models';

@Injectable({
  providedIn: 'root',
})
export class GravelService {
  constructor(private store: Store<DigitalTicketNew>, private functionService: FunctionService) {}

  getLettersArray(
    elements: Partial<Provider>[] | SimpleContractor[] | ActivityForTicket[] | Budget[] | BudgetGroup[],
    attribute: string,
  ): string[] {
    const letters = [];
    elements.forEach((el) => {
      letters.push(this.getFirstLetter(el[attribute]));
    });
    return uniq(letters).sort();
  }

  getFirstLetter(name: string): string {
    const normalizedName = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return normalizedName[0].toUpperCase();
  }

  getQuantity(details: Detail[], budgetsIds: number[]) {
    return (
      details?.reduce((acum, detail) => {
        return budgetsIds.includes(detail.budgetId) ? acum + detail.quantity : acum;
      }, 0) ?? 0
    );
  }

  getPOMaxQuantity(details: PODetail[], materialId: number) {
    return (
      details?.reduce((acum, detail) => {
        return detail.material.id === materialId ? acum + detail.quantityConverted : acum;
      }, 0) ?? 0
    );
  }

  getProposedMaxQuantity(details: Detail[], budgetsIds: number[], materialId: number) {
    return (
      details?.reduce((acum, detail) => {
        if (budgetsIds.includes(detail.budgetId) && detail.materialId === materialId) {
          return acum + detail.maxQuantity;
        }
        return acum;
      }, 0) ?? 0
    );
  }
  getBudgetedQuantity(details: Detail[], budgetsIds: number[]) {
    return (
      details?.reduce((acum, detail) => {
        return budgetsIds.includes(detail.budgetId) ? acum + detail.budgetedQuantity : acum;
      }, 0) ?? 0
    );
  }

  getExtraQuantity(details: Detail[], budgetsIds: number[]) {
    return (
      details?.reduce((acum, detail) => {
        return budgetsIds.includes(detail.budgetId) ? acum + detail.extraQuantity : acum;
      }, 0) ?? 0
    );
  }

  getOverloadQuantity(details: Detail[], budgetsIds: number[]) {
    return (
      details?.reduce((acum, detail) => {
        return budgetsIds.includes(detail.budgetId) ? acum + detail.overload : acum;
      }, 0) ?? 0
    );
  }

  getDocumentTypesForGravel() {
    return [
      {
        value: TributaryDocumentType.FACTURA,
        name: processTributaryDocumentType(TributaryDocumentType.FACTURA),
      },
      {
        value: TributaryDocumentType.GUIA_DESPACHO,
        name: processTributaryDocumentType(TributaryDocumentType.GUIA_DESPACHO),
      },
      {
        value: TributaryDocumentType.OTHER,
        name: processTributaryDocumentType(TributaryDocumentType.OTHER),
      },
    ];
  }

  public getSelections() {
    return this.store.select(TicketSelectors.getSelections);
  }

  public resetSelections() {
    this.store.dispatch(TicketActions.ResetSelections());
  }

  public saveProvider(provider: Partial<Provider>) {
    this.store.dispatch(TicketActions.SaveProvider({ provider }));
  }

  public saveMaterial(material: Material) {
    this.store.dispatch(TicketActions.SaveMaterial({ material }));
  }

  public saveContractor(contractor: SimpleContractor) {
    this.store.dispatch(TicketActions.SaveContractor({ contractor }));
  }

  public saveActivityType(activityType: ActivityType) {
    this.store.dispatch(TicketActions.SaveActivityType({ activityType }));
  }

  public saveActivity(activity: ActivityForTicket) {
    this.store.dispatch(TicketActions.SaveActivity({ activity }));
  }

  public saveBudgetGroup(budgetGroup: BudgetGroup) {
    this.store.dispatch(TicketActions.SaveBudgetGroup({ budgetGroup }));
  }

  public saveBudgets(budgets: Budget[]) {
    this.store.dispatch(TicketActions.SaveBudgets({ budgets }));
  }

  public savePO(po: PO) {
    this.store.dispatch(TicketActions.SavePO({ po }));
  }

  public saveDetails(details: Detail[]) {
    this.store.dispatch(TicketActions.SaveDetails({ details }));
  }

  public saveUrbaActivityQuantity(urbaActivityQuantity: number) {
    this.store.dispatch(TicketActions.SaveUrbaActivityQuantity({ urbaActivityQuantity }));
  }

  public saveIngressInfo(ingressInfo: IngressInfo) {
    this.store.dispatch(TicketActions.SaveIngressInfo({ ingressInfo }));
  }
}
