/*
 * File: niceCurrency.pipe.ts
 * Project: galilea-centro
 * File Created: Thursday, 20th May 2021 16:09:34 pm
 * Author: Kevin Johnson (kevin@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Pipe, PipeTransform } from '@angular/core';
import { capitalize, trim } from 'lodash';

@Pipe({
  name: 'capitalizeEach',
})
export class CapitalizeEachPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    return value
      .split(' ')
      .filter(Boolean)
      .map(trim)
      .map((word) => {
        if (word.includes('.')) return word.toUpperCase();
        if (word.length <= 1) return word.toLowerCase();
        return capitalize(word);
      })
      .join(' ');
  }
}
