/*
 * File: activity.ts
 * Project: galilea-centro
 * File Created: Thursday, 19th March 2020 10:42:38 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { PurchasePlan } from './purchasePlan';
import { Material } from './materialItems';
import { BudgetGroup } from './budgetGroup';
import { MaterialPriceAgreement } from './priceAgreement';
import { Specialty } from './specialty';
import { WorkPackage } from './workPackage';
import { ActivityCategory } from './activityCategory';
import { User } from './user';
import { WorkSubpackage } from './workSubpackage';

// eslint-disable-next-line no-shadow
export enum ActivityType {
  house = 'HOUSE',
  urba = 'URBANIZATION',
  other = 'OTHER',
  warehouse = 'WAREHOUSE',
  service = 'SERVICE',
  house_repair = 'HOUSE_REPAIR',
  urba_repair = 'URBANIZATION_REPAIR',
  other_repair = 'OTHER_REPAIR',
  labor = 'LABOR',
  building = 'BUILDING',
  building_repair = 'BUILDING_REPAIR',
}

// eslint-disable-next-line no-shadow
export enum ActivityStatus {
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
}

/**
 * Array with all repair activity types
 */
export const repairActivityTypes = [
  ActivityType.house_repair,
  ActivityType.other_repair,
  ActivityType.urba_repair,
  ActivityType.building_repair,
];

/**
 * Array with house types for activities
 */
export const houseTypes = [ActivityType.house, ActivityType.house_repair];

/**
 * Array with urbanization types for activities
 */
export const urbanizationTypes = [ActivityType.urba, ActivityType.urba_repair];

/**
 * Array with building types for activities
 */
export const buildingTypes = [ActivityType.building, ActivityType.building_repair];

/**
 * Array with other types for activities
 */
export const otherTypes = [ActivityType.other, ActivityType.other_repair];

export function getActivityTypesArray(type: ActivityType): ActivityType[] {
  switch (type) {
    case ActivityType.house:
      return houseTypes;
    case ActivityType.urba:
      return urbanizationTypes;
    case ActivityType.other:
      return otherTypes;
    case ActivityType.house_repair:
      return houseTypes;
    case ActivityType.urba_repair:
      return urbanizationTypes;
    case ActivityType.other_repair:
      return otherTypes;
    default:
      return [type];
  }
}

export const activityMacroTypes = [ActivityType.house, ActivityType.building, ActivityType.urba, ActivityType.other];

export interface Activity {
  id: number;
  name: string;
  activityType: string;
  purchasePlans: PurchasePlan[];
  hasMaterials: boolean;
  type: ActivityType;
  materials: Material[];
  order?: number;
  scope: string[];
  cubicationFormat: string;
  baseUnit: string;
  foundationFormats?: {
    name: string;
  }[];
  budgetGroups?: BudgetGroup[];
  receptionImageRequired?: boolean;
  specialty: Specialty;
  activityCategory: {
    id: number;
    name: string;
  };
  workPackage: WorkPackage;
  workSubpackage: WorkSubpackage;
  manHour: number;
  observation: string;
  status: ActivityStatus;
  enabled: boolean;
  updatedBy?: User;
  updatedAt?: string;
  requestedBy?: User;
  requestedAt?: string;
  approvedBy?: User;
  approvedAt?: string;
}

export interface Restriction {
  houseFormat: string;
  foundationFormat: string;
  orientation: string;
  firstFloorColor: string;
  secondFloorColor: string;
  thermalZone: string;
  window: string;
  sanitary: string;
}

/*export interface BudgetGroupActivity {
  repetitions: number;
  price: number;
  activity: {
    id: number;
    name: string;
    cubicationFormat: string;
    type: string;
    foundationFormats?: {
      name: string;
    }[];
  };
  materials: {
    quantity: number;
    price: number;
    material: {
      id: number;
      name: string;
      quantificationMetric: string;
    };
    restriction: Restriction;
  }[];
}*/

export interface BGActivityMaterial {
  quantity: number;
  price: number;
  originalPrice?: number;
  restriction: Restriction;
  material: {
    id: number;
    name: string;
    quantificationMetric: string;
  };
  priceAgreements: MaterialPriceAgreement[];
}

export interface BGActivity {
  repetitions: number;
  baseUnitCubication: number;
  price: number;
  originalPrice?: number;
  order: number;
  activity: Activity;
  materials: BGActivityMaterial[];
}
export interface repairBGActivity extends BGActivity {
  budgets: {
    name: string;
    id: number;
  }[];
}

export interface ActivityBudgetSummary extends Activity {
  summaryBudget: number;
  summaryPaidInvoice: number;
  summarySentPurchaseOrder: number;
  yearlySummaryBudget: number;
  yearlySummaryPaidInvoice: number;
  yearlySummarySentPurchaseOrder: number;
}

export interface ActivityForTicket {
  id: number;
  name: string;
  cubicationFormat: string;
  specialty: {
    id: number;
    name: string;
  };
  order: number;
  materials: {
    id: number;
    name: string;
    nickName: string;
  }[];
  quantities?: {
    cubicatedQuantity: number;
  }[];
  workPackage?: WorkPackage;
  activityCategory?: ActivityCategory;
  // For rendering purposes:
  firstLetterFixed?: string;
}

// TODO: remove and use activity type
// eslint-disable-next-line no-shadow
export enum ActivityCategorySimple {
  'HOUSE' = 'HOUSE',
  'URBA' = 'URBA',
  'BUILDING' = 'BUILDING',
  'OTHER' = 'OTHER',
}
