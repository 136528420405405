// eslint-disable-next-line no-shadow
export enum InvoiceChangeStatusAction {
  /**
   * Document will be accepted
   */
  ACCEPT = 'ACCEPT',

  /**
   * Document will be rejected due to price
   */
  REJECT_PRICE = 'REJECT_PRICE',

  /**
   * Document will be rejected due to items not received yet
   */
  REJECT_MISSING = 'REJECT_MISSING',

  /**
   * Document will be rejected due to automatic process
   */
  REJECT_AUTOMATIC = 'REJECT_AUTOMATIC',

  /**
   * Document already accepted will be matched with a purchase order
   */
  MATCH = 'MATCH',
}
