import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-one-column-date-card',
  templateUrl: './one-column-date-card.component.html',
  styleUrls: ['./one-column-date-card.component.scss'],
})
export class OneColumnDateCardComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() current: Date;
  date: FormControl<Date>;
  @Output() selected: EventEmitter<{ value: Date }> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {
    this.date = new FormControl<Date | null>(null);
    this.date.setValue(this.current);
  }
  ngOnChanges(): void {
    if (this.current) this.date?.setValue(this.current);
  }

  handleDateChange(to: Date) {
    this.selected.emit({ value: new Date(to.toDateString()) });
  }
}
