import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-simple-confirm',
  templateUrl: './simple-confirm.component.html',
  styleUrls: ['../abstract-modal.component.scss', './simple-confirm.component.scss'],
})
export class SimpleConfirmComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SimpleConfirmComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      content: string[];
      centered: boolean;
      decline: string;
      accept: string;
    },
  ) {}

  ngOnInit(): void {}
  close() {
    this.dialogRef.close({
      confirm: false,
    });
  }
  approve() {
    this.dialogRef.close({
      confirm: true,
    });
  }
}
