export const ROUTES = {
  mobile: {
    path: '',
    advance: {
      path: 'avance',
    },
    approval: {
      path: 'aprobaciones',
    },
    activityReception: {
      path: 'recepciones',
    },
    settings: {
      path: 'settings',
    },
    commonCreation: {
      contractor: {
        path: 'contratista',
      },
      activity: {
        path: 'partida',
      },
      stage: {
        path: 'etapa',
      },
      lot: {
        path: 'lote',
      },
      summary: {
        path: 'resumen',
      },
    },
    ticketCreation: {
      root: {
        path: 'ticket',
      },
      selectMaterials: {
        path: 'seleccionar-materiales',
      },
      materials: {
        path: 'materiales',
      },
      urbanBudget: {
        path: 'urban-presupuesto',
      },
      otherBudget: {
        path: 'otro-presupuesto',
      },
      urbanActivity: {
        path: 'urban-partida',
      },
      otherActivity: {
        path: 'otro-partida',
      },
    },
    gravelReception: {
      root: {
        path: 'aridos',
      },
      provider: {
        path: 'proveedor',
      },
      material: {
        path: 'material',
      },
      po: {
        path: 'oc',
      },
      selectPo: {
        path: 'seleccionar-oc',
      },
      quantity: {
        path: 'cantidad',
      },
      ingress: {
        path: 'ingreso',
      },
      budget: {
        path: 'presupuesto',
      },
    },
    //   path: 'requests',
    //   root: {
    //     path: '',
    //   },
    //   id: {
    //     path: ':id',
    //   },
    // },
    // planning: {
    //   path: 'planificacion',
    // },
    // payments: {
    //   path: 'estados-de-pago',
    // },
    // purchasePlan: {
    //   path: 'plan-de-liberacion',
    // },
  },
};

type BuildNavBarRoutes = () => { [key: string]: string };
export const buildNavBarRoutes: BuildNavBarRoutes = () => ({
  gravelPath: `/terreno/${ROUTES.mobile.gravelReception.root.path}`,
  advancePath: `/terreno/${ROUTES.mobile.advance.path}`,
  approvalPath: `/terreno/${ROUTES.mobile.approval.path}`,
  ticketPath: `/terreno/${ROUTES.mobile.ticketCreation.root.path}`,
  receptionPath: `/terreno/${ROUTES.mobile.activityReception.path}`,
  settingsPath: `/terreno/${ROUTES.mobile.settings.path}`,
  // planningPath: `/terreno/${ROUTES.mobile.planning.path}`,
  // paymentsPath: `/terreno/${ROUTES.mobile.payments.path}`,
});
