// tslint:disable: no-string-literal
import { Injectable } from '@angular/core';
import { Auth, User } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { GraphqlService } from './graphql.service';
import { GlobalService } from './global.service';
import { UserInfo } from '@app/models/user';
import * as Sentry from '@sentry/browser';
import * as idb from 'idb';
import gql from 'graphql-tag';
import packageJson from '@app/../../package.json';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private authFirebase: Auth,
    private router: Router,
    private graphql: GraphqlService,
    private globalService: GlobalService,
  ) {}

  addCallbackOnAuthStateChanged(callback: (e: User) => void) {
    return this.authFirebase.onAuthStateChanged(callback);
  }

  async clearAndRedirect(): Promise<void> {
    await this.graphql.reset();
    this.redirectLogin();
  }

  async logout(): Promise<void> {
    Sentry.configureScope((scope) => scope.setUser(null));
    // ['branchId', 'branchLastSelected'].forEach((k) => localStorage.removeItem(k));
    ['branchId'].forEach((k) => localStorage.removeItem(k));
    sessionStorage.removeItem('branchId');
    this.globalService.resetSelections();
    this.authFirebase.signOut().then(async () => {
      ['branchId'].forEach((k) => {
        localStorage.removeItem(k);
        sessionStorage.removeItem(k);
      });
      await idb.deleteDB('keyval-store', {
        blocked: this.clearAndRedirect.bind(this),
      });
      await this.clearAndRedirect();
    });
  }

  redirectMobile(): void {
    this.router.navigateByUrl('terreno');
  }

  redirectOffice(): void {
    this.router.navigateByUrl('bodeguero');
  }

  redirectDashboard(): void {
    this.router.navigateByUrl('index');
  }

  redirectLogin(): void {
    this.router.navigateByUrl('login');
  }

  redirectEmailLogin(): void {
    this.router.navigateByUrl('login/email');
  }

  redirectHome(): void {
    this.router.navigateByUrl('');
  }

  isLogged(): Promise<boolean> {
    return new Promise((res) => {
      this.authFirebase.onAuthStateChanged((user) => {
        res(!!user);
      });
    });
  }

  async getJWT() {
    if (!this.authFirebase.currentUser) return null;
    const idToken = await this.authFirebase.currentUser.getIdToken();
    return idToken;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  userData() {
    return this.graphql.query<{ userInfo: UserInfo }>(
      gql`
        query userInfo($params: UserInfoParams) {
          userInfo(params: $params) {
            id
            name
            email
            mobile
            branches {
              id
              name
              warehouse {
                id
                name
                address
              }
            }
            role {
              id
              name
              permissions {
                id
                name
              }
            }
            team {
              id
              name
            }
          }
        }
      `,
      {
        fetchPolicy: 'cache-and-network',
        variables: {
          params: { frontendVersion: packageJson.version },
        },
      },
    ).valueChanges;
  }
}
