import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-one-column-button-card',
  templateUrl: './one-column-button-card.component.html',
  styleUrls: ['./one-column-button-card.component.scss'],
})
export class OneColumnButtonCardComponent implements OnInit {
  @Input() attr: string;
  @Input() title: string;
  @Input() body: string;
  @Input() icon: string;
  @Output() selected: EventEmitter<{ attr: string }> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  click(): void {
    this.selected.emit({ attr: this.attr });
  }
}
