import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-icon-registry',
  templateUrl: './icon-registry.component.html',
  styleUrls: ['./icon-registry.component.scss'],
})
export class IconRegistryComponent implements OnInit {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon(
      'whatsapp',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/whatsapp.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-image',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/image.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-video',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/video.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-attach',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/attach.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-warning',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/warning.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'custom-alert',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/alert.svg'),
    );
  }

  ngOnInit() {}
}
