import { environment } from '@env/environment';
import { round } from 'lodash';

const getDifference =
  (decimalPlaces: number) =>
  (a: number, b: number, absolute = false): number => {
    const rounded = round(a - b, decimalPlaces);
    return absolute ? Math.abs(rounded) : rounded;
  };

export const getVisibleDiff = {
  r: getDifference(environment.decimals.r),
  p: getDifference(environment.decimals.p),
  pUf: getDifference(environment.decimals.pUf),
  q: getDifference(environment.decimals.q),
  pq: getDifference(environment.decimals.pq),
};
