/*
 * File: niceCurrency.pipe.ts
 * Project: galilea-centro
 * File Created: Tuesday, 5th May 2020 10:09:34 am
 * Author: Kevin Johnson (kevin@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe as AngularCurrencyPipe } from '@angular/common';

export type CurrencyCode = 'CLP' | 'CLF';

@Pipe({
  name: 'niceCurrency',
})
export class NiceCurrencyPipe implements PipeTransform {
  CP = new AngularCurrencyPipe('es-CL');
  defaultDigits = {
    CLF: '1.0-2',
    CLP: '1.0-0',
  };
  /*
   * Examples:
   * transform(12345)
   * transform(12345, 'CLP')
   * transform(12345, 'CLF')
   * transform(12345, 'CLP', '1.0-2')
   * transform(12345, 'CLF', '1.0-2')
   */
  transform(value: number, currencyCode: CurrencyCode = 'CLP', digitsInfo?: string): string {
    if (!value && value !== 0) return '';
    const display = currencyCode === 'CLF' ? '' : undefined;
    const _digitsInfo = digitsInfo ?? this.defaultDigits[currencyCode] ?? '1.0-0';
    const sufix = currencyCode === 'CLF' ? ' UF' : '';
    return this.CP.transform(value, currencyCode, display, _digitsInfo) + sufix;
  }
}
