<mat-card [ngClass]="size === 'double' ? 'double' : 'simple'">
  <p>
    {{ title }}
    <mat-icon
      [ngClass]="{
        'red-icon': icon === 'close',
        'green-icon': icon === 'done',
        'gray-icon': icon === 'remove'
      }"
      >{{ icon }}</mat-icon
    >
  </p>
  <div class="to-bottom">
    <div
      isEllipsisActive
      [matTooltip]="body"
      [matTooltipShowDelay]="500"
      class="body"
      [ngClass]="{ fullDisplay: isFullDisplay() }"
    >
      {{ body }}
    </div>
  </div>
</mat-card>
