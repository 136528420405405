<mat-card>
  <p>
    {{ title }}
    <mat-icon
      *ngIf="icon"
      [matTooltip]="iconTooltip"
      matTooltipPosition="right"
      [ngClass]="{ hasCallback: !!iconCallback }"
      (click)="iconCallback ? iconCallback() : null"
      >{{ icon }}</mat-icon
    >
  </p>
  <!-- <mat-form-field> -->
  <div *ngIf="!searchable">
    <mat-select
      [disabled]="disabled"
      (selectionChange)="selectionChange($event.value)"
      [(value)]="currentValue"
      [ngClass]="{ 'is-invalid': isInvalid }"
    >
      <mat-option *ngFor="let type of selections" [value]="type.value" [disabled]="type.disabled">{{
        type.name
      }}</mat-option>
    </mat-select>
  </div>
  <div *ngIf="searchable" class="autocomplete" [ngClass]="{ 'is-invalid': isInvalid }">
    <mat-form-field class="example-full-width">
      <input
        class="searchable-input"
        type="text"
        aria-label="Number"
        matInput
        [matAutocomplete]="auto"
        (input)="search($event.target.value)"
        [(ngModel)]="temp"
      />
      <mat-autocomplete
        class="dropDown"
        [panelWidth]="panelWidth"
        #auto="matAutocomplete"
        (optionSelected)="selectionChange($event.option.value)"
        [displayWith]="displayFn.bind(this)"
      >
        <ng-container *ngIf="filteredSelections.length > 0; else noResults">
          <mat-option *ngFor="let type of filteredSelections" [value]="type.value">{{ type.name }}</mat-option>
        </ng-container>
        <ng-template #noResults>
          <mat-option disabled> No hay opciones disponibles </mat-option>
        </ng-template>
      </mat-autocomplete>
    </mat-form-field>
    <mat-icon *ngIf="enableClearButton && temp" (click)="onClear()">close</mat-icon>
  </div>
  <!-- </mat-form-field> -->
</mat-card>
