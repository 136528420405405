import { Component, OnInit, EventEmitter, Output, Input, ViewChild, ElementRef } from '@angular/core';
import { UploadService } from '@app/services/upload.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  progress: number;
  uploading = false;
  uploaded = false;
  name = '';
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() meta: string;
  @Input() url: string;
  @Input() disabled = false;
  @Output() result: EventEmitter<{ name: string; url: string }> = new EventEmitter();
  constructor(
    private readonly fileService: UploadService,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon('upload', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/upload.svg'));
  }
  upload(files: FileList) {
    if (files.length === 0) {
      return;
    }
    this.uploading = true;
    this.progress = 0;

    this.name = files.item(0).name;
    this.fileService
      .getPresignedUrl({ fileName: this.name, uploadPath: this.meta, type: files.item(0).type })
      .subscribe((res: ApolloQueryResult<{ getSignedUrl: { url: string; uploadName: string } }>) => {
        const { url, uploadName } = res.data.getSignedUrl;
        this.fileService.uploadAndProgress(files, url).subscribe((event) => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = this.fileService.calcProgressPercent(event);
          } else if (event instanceof HttpResponse) {
            this.uploading = false;
            this.uploaded = true;
            this.url = url.split('?')[0];
            this.result.emit({
              name: this.name,
              url: this.url,
            });
          }
        });
      });
  }
  processDelete() {
    if (!this.uploaded) {
      return;
    }
    this.fileInput.nativeElement.value = null;
    this.uploaded = false;
    this.uploading = false;
    this.name = '';
    this.result.emit({
      name: this.name,
      url: '',
    });
    /**
     * TODO: TBD
     */
  }
  ngOnInit() {
    this.setDefault();
  }
  ngOnChanges() {
    this.setDefault();
  }
  setDefault() {
    if (this.url !== '') {
      this.name = this.url.split('/').pop();
      this.uploaded = true;
      this.uploading = false;
    } else {
      this.name = '';
      this.uploaded = false;
      this.uploading = false;
    }
  }
}
