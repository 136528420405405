import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { environment } from '@env/environment';
import { SectionGuard } from '@app/guards/section.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  { path: '', loadChildren: () => import('@views/home/home.module').then((m) => m.HomeModule) },
  {
    path: 'bodeguero',
    loadChildren: () => import('@views/warehouse/warehouse.module').then((m) => m.WarehouseModule),
    canActivate: environment.auth ? [AuthGuard, SectionGuard] : [],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'terreno',
    loadChildren: () => import('@views/mobile/mobile.module').then((m) => m.MobileModule),
    canActivate: environment.auth ? [AuthGuard, SectionGuard] : [],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'publico',
    loadChildren: () => import('@views/public/public.module').then((m) => m.PublicModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
