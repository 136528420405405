/*
 * File: loader.service copy.ts
 * Project: galilea-centro
 * File Created: Friday, 12th June 2020 12:05:36 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as GlobalActions from '../store/actions/global.actions';
import * as GlobalSelectors from '../store/selectors/global.selectors';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Console } from '@app/utils';

@Injectable({
  providedIn: 'root',
})
export class ConcurrentLoaderService {
  loadings = {};
  constructor(private store: Store<any>) {}

  public showLoader(key: string) {
    this.loadings[key] = (this.loadings[key] || 0) + 1;
    Console.log('show loader:', key, this.loadings);
    this.store.dispatch(GlobalActions.ShowConcurrentLoader());
  }

  public hideLoader(key: string) {
    const newLoadingValue = (this.loadings[key] || 0) - 1;
    this.loadings[key] = Math.max(newLoadingValue, 0);
    Console.log('hide loader:', key, this.loadings);
    if (newLoadingValue >= 0) this.store.dispatch(GlobalActions.HideConcurrentLoader());
  }

  public getLoader(): Observable<boolean> {
    return this.store.select(GlobalSelectors.getConcurrentLoader).pipe(map((n) => n > 0));
  }
}
