/*
 * File: app.module.ts
 * Project: galilea-centro
 * File Created: Thursday, 3rd October 2019 10:28:00 am
 * Author: Hector Piñero (hector@inventures.cl)
 * -----
 * Copyright 2019 - 2019 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Injectable, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideFirebaseApp, initializeApp as initializeFireApp } from '@angular/fire/app';
import { provideAuth, connectAuthEmulator, getAuth } from '@angular/fire/auth';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule, MetaReducer, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule } from '@ngrx/data';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from '@core/app-routing.module';
import { environment } from '@env/environment';
import { AppEffects } from '@effects/app.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import * as fromGlobal from '@reducers/index';
import * as fromTicket from '@reducers/ticket.reducer';
import * as fromGravel from '@reducers/gravel.reducer';
import { ReusableModule } from '@views/reusable/reusable.module';
import { CustomSerializer } from '@reducers/custom-route-serializer';
import { GraphQLModule } from './graphql.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AnimationService } from '@services/animation.service';
import { GravelRoutesGuard } from './guards/gravel-routes.guard';

import { AppComponent } from './app.component';
import { IconRegistryModule } from '@app/icon-registry/icon-registry.module';
import { AuthGuard } from '@angular/fire/auth-guard';
import { registerLocaleData } from '@angular/common';
import localeCl from '@angular/common/locales/es-CL';
import * as Sentry from '@sentry/browser';
import packageJson from '../../package.json';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ApolloModule } from 'apollo-angular';

if (environment.production) {
  Sentry.init({
    dsn: 'https://8b69862b4a8f465f9854c5e156ab1b35@sentry.io/2736750',
    environment: environment.sentryEnv,
    release: `galileapro@${packageJson.version}`,
  });
}
registerLocaleData(localeCl);
export function getMetaReducers(): MetaReducer<fromGlobal.AppState>[] {
  if (!environment.production) {
    return [];
  }
  return [];
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error): void {
    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.error(error);
      return;
    }
    Sentry.captureException(error.originalError || error);
  }
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ApolloModule,
    provideFirebaseApp(() => initializeFireApp(environment.firebaseConfig)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.emulatorUrl) {
        connectAuthEmulator(auth, environment.emulatorUrl, { disableWarnings: true });
      }
      return auth;
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.prerelease,
    }),
    StoreModule.forRoot(fromGlobal.appReducer, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      },
    }),
    StoreModule.forFeature(fromTicket.ticketFeatureKey, fromTicket.reducer),
    StoreModule.forFeature(fromGravel.ticketFeatureKey, fromGravel.reducer),
    EffectsModule.forRoot([AppEffects]),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: CustomSerializer,
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EntityDataModule,
    HttpClientModule,
    ReusableModule,
    GraphQLModule,
    MatSnackBarModule,
    IconRegistryModule,
    HammerModule,
    MatNativeDateModule,
  ],
  providers: [
    AnimationService,
    AuthGuard,
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: getMetaReducers,
    },
    GravelRoutesGuard,
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
