<mat-card>
  <p>{{ title }}</p>
  <div class="body">
    <mat-form-field class="container">
      <input
        matInput
        [formControl]="date"
        [matDatepicker]="to"
        (dateChange)="handleDateChange(date.value)"
        autocomplete="off"
        disabled
      />
      <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
      <mat-datepicker #to disabled="false"></mat-datepicker>
    </mat-form-field>
  </div>
</mat-card>
