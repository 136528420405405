/*
 * File: section.guard.ts
 * Project: galilea-centro
 * File Created: Friday, 17th January 2020 2:30:27 pm
 * Author: Hector Piñero (hector@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { GlobalService } from '@app/services/global.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SectionGuard implements CanActivate, OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  constructor(private globalService: GlobalService) {}

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const section = _state.url.split('/').filter(Boolean)[0]?.toLowerCase();
    const permissionToCheck = section === 'bodeguero' ? 'ACCESS_OFFICE_VIEW' : 'ACCESS_FIELD_VIEW';
    return this.globalService.getUserPermissions().pipe((permissionsSubscription) => {
      return new Observable<boolean>((subscriber) => {
        permissionsSubscription.pipe(takeUntil(this.ngUnsubscribe)).subscribe((permissions) => {
          if (permissions.length) {
            const has = Boolean(permissions.includes(permissionToCheck));
            subscriber.next(has);
          }
        });
      });
    }, takeUntil(this.ngUnsubscribe));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
