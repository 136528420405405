/*
 * File: global.service.ts
 * Project: galilea-centro
 * File Created: Tuesday, 10th December 2019 11:41:44 am
 * Author: Hector Piñero (hector@inventures.cl)
 * -----
 * Copyright 2019 - 2019 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import * as GlobalActions from '../store/actions/global.actions';
import * as GlobalSelectors from '../store/selectors/global.selectors';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { getFromLocalStorage, getFromSessionStorage, saveToLocalStorage, saveToSessionStorage } from '@app/utils';

import { QueryRef } from 'apollo-angular';
import { AppStatusResult } from '@app/models/user';
import { GraphqlService } from './graphql.service';
import gql from 'graphql-tag';
import packageJson from '@app/../../package.json';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  $permissions = new BehaviorSubject(null);

  constructor(private store: Store<any>, private graphql: GraphqlService, private http: HttpClient) {
    const id = getFromSessionStorage('branchId') ?? getFromLocalStorage('branchId');
    if (id) this.store.dispatch(GlobalActions.setBranch({ id }));
  }

  setBranch(id: number): void {
    this.store.dispatch(GlobalActions.setBranch({ id }));
    saveToLocalStorage('branchId', id);
    saveToSessionStorage('branchId', id);
  }

  getBranch(): Observable<number> {
    return this.store.select(GlobalSelectors.getBranch);
  }

  setUserPermissions(permissions: string[]): void {
    this.store.dispatch(GlobalActions.setUserPermissions({ permissions }));
    saveToLocalStorage('userPermissions', permissions);
    this.$permissions.next(permissions);
  }
  getUserPermissions(): Observable<string[]> {
    return this.store.select(GlobalSelectors.getUserPermissions).pipe((permissionsSubscription) => {
      return new Observable((subscriber) => {
        permissionsSubscription.subscribe((permissions) => {
          subscriber.next(permissions.length ? permissions : getFromLocalStorage('userPermissions') || []);
        });
      });
    });
  }

  // clear old stored & store new number and country code
  storeNumber(code: string | null, phone: string | null): void {
    if (code) saveToLocalStorage('code', code);
    if (phone) saveToLocalStorage('phone', phone);
  }

  // get submitted phone numer from localStorage
  getStoredNumber(): { code: string; phone: string } {
    return {
      code: getFromLocalStorage('code'),
      phone: getFromLocalStorage('phone'),
    };
  }

  resetSelections() {
    return this.store.dispatch(GlobalActions.resetSelections());
  }
  appStatus(): QueryRef<AppStatusResult> {
    return this.graphql.query<AppStatusResult>(
      gql`
        query appStatus($params: AppStatusParams) {
          appStatus(params: $params) {
            sameVersion
          }
        }
      `,
      {
        fetchPolicy: 'no-cache',
        variables: {
          params: {
            version: packageJson.version,
          },
        },
      },
    );
  }
  async openPDF(url: string) {
    window.open(url, '_blank');
  }
}
