import { differenceInDays, differenceInMinutes } from 'date-fns';
import { floor } from 'lodash';

function pluralize(text: string, time: number) {
  return text + (time > 1 ? 's' : '');
}
export function calculateRemainingTime(date: string): string {
  const endDate = new Date(date);
  const now = new Date();
  const diff = Math.max(0, differenceInMinutes(endDate, now));
  if (!diff) return '0 minutos';
  const days = differenceInDays(endDate, now);
  const hours = floor((diff - 1440 * days) / 60);
  const minutes = diff - 1440 * days - 60 * hours;
  const text = [
    days ? `${days} ${pluralize('día', days)}` : '',
    hours ? `${hours} ${pluralize('hora', hours)}` : '',
    minutes ? `${minutes} ${pluralize('minuto', minutes)}` : '',
  ];
  return text
    .filter((t) => !!t)
    .slice(0, 2)
    .join(', ');
}

export function isThereTimeLeft(date: string, maxMinutes: number): boolean {
  if (!date) return false;
  const curr_date = new Date();
  const BGdate = date ? new Date(date) : null;
  return differenceInMinutes(curr_date, BGdate) < maxMinutes;
}
