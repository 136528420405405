import { Action, createReducer, on } from '@ngrx/store';
import * as TicketActions from '@actions/ticket.actions';
import { DigitalTicket, TicketType } from '@models/ticket';

export const ticketFeatureKey = 'ticket';

export const initialState: DigitalTicket = {
  selectedMaterials: [],
  selectedActivity: null,
  selectedContractor: null,
  selectedBudgetGroup: null,
  selectedBudgets: [],
  type: null,
  explanation: {
    reason: null,
    comment: null,
  },
  urbaActivityQuantity: 1,
};

const ticketReducer = createReducer(
  initialState,
  on(TicketActions.loadTickets, (state) => state),
  on(TicketActions.SelectContractor, (state, { contractor }) => {
    return {
      ...state,
      selectedContractor: contractor,
      selectedActivity: null,
      selectedMaterials: [],
    };
  }),
  on(TicketActions.EditReciever, (state, { picker }) => {
    return {
      ...state,
      selectedContractor: {
        ...state.selectedContractor,
        picker,
      },
    };
  }),
  on(TicketActions.SelectActivity, (state, { activity }) => {
    return {
      ...state,
      selectedActivity: activity,
      selectedMaterials: [],
    };
  }),
  on(TicketActions.SelectBudgetGroup, (state, { budgetGroup }) => {
    return {
      ...state,
      selectedBudgetGroup: budgetGroup,
    };
  }),
  on(TicketActions.SelectBudgets, (state, { budgets }) => {
    return {
      ...state,
      selectedBudgets: [
        ...budgets.map(({ materials = [], ...budget }) => ({
          ...budget,
          materials,
        })),
      ],
    };
  }),
  on(TicketActions.ResetActivity, (state) => {
    return {
      ...state,
      selectedBudgets: [],
      selectedMaterials: [],
      explanation: {
        reason: null,
        comment: null,
      },
    };
  }),
  on(TicketActions.SelectMaterials, (state, { selectedMaterials }) => {
    return {
      ...state,
      selectedMaterials,
    };
  }),
  on(TicketActions.TicketHouse, (state) => {
    return {
      ...state,
      type: TicketType.house,
    };
  }),

  on(TicketActions.TicketUrba, (state) => {
    return {
      ...state,
      type: TicketType.urba,
    };
  }),

  on(TicketActions.TicketOther, (state) => {
    return {
      ...state,
      type: TicketType.other,
    };
  }),

  on(TicketActions.ChangeTicketType, (state, { ticketType }) => {
    return {
      ...state,
      type: ticketType,
    };
  }),
  on(TicketActions.SelectExplanation, (state, { explanation }) => {
    return {
      ...state,
      explanation,
    };
  }),
  on(TicketActions.SelectUrbaActivityQuantity, (state, { urbaActivityQuantity }) => {
    return {
      ...state,
      urbaActivityQuantity,
    };
  }),
);

export function reducer(state: DigitalTicket = initialState, action: Action) {
  return ticketReducer(state, action);
}
