import { Activity } from './activity';
import { ActivityType } from './activity';
import { Budget } from './budget';
import { ContractDetail } from './contractor';
import { Provider } from './provider';
import { User } from './user';

// eslint-disable-next-line no-shadow
export enum ReceptionStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
}

/**
 * Function to translate a string value to its enum representation
 *
 * @export
 * @param {string} s String to translate
 * @return {*}  {ReceptionStatus} Enum representation or null if invalid value
 */
export function translateReceptionStatus(s: string): ReceptionStatus {
  switch (s?.toUpperCase()) {
    case 'PENDING':
      return ReceptionStatus.PENDING;
    case 'PAID':
      return ReceptionStatus.PAID;
    case 'REJECTED':
      return ReceptionStatus.REJECTED;
    case 'ACCEPTED':
      return ReceptionStatus.ACCEPTED;
    default:
      return null;
  }
}

// eslint-disable-next-line no-shadow
export enum ReceptionStatusNames {
  PENDING = 'Pendiente aprobación',
  PAID = 'Pagada',
  REJECTED = 'Rechazada',
  ACCEPTED = 'Aprobada',
}
export interface Reception {
  id: number;
  status: string;
  comment: string;
  isOverrun?: boolean;
  provider: Provider;
  activity: {
    id: number;
    type: ActivityType;
    name: string;
    scope: string[];
  };
  activityReceptionDetails: {
    id: number;
    attachmentURLs: string[];
    repetitions: number;
    totalOverrun: number;
    budget: Budget;
  }[];
  activityReceptionObjections: {
    id: number;
  };
  createdAt: Date;
  createdBy: {
    id: number;
  };
}

export interface ActivityReceptionDetail {
  id: number;
  budget?: Budget;
  repetitions: number;
  attachmentURLs?: string[];
  repetitionPrice: number;
  originalRepetitionPrice: number;
  contractDetail?: ContractDetail;
  cubicatedRepetitions: number;
  budgetPaidRepetitions: number;
  overrunPaidRepetitions: number;
  acceptedBudgetRepetitions: number;
  acceptedOverrunRepetitions: number;
  totalOverrun: number;
}

export interface ActivityReception {
  id: number;
  status: ReceptionStatus;
  activity?: Activity;
  isOverrun?: boolean;
  excludeMaterials?: boolean;
  createdAt?: string;
  createdBy?: User;
  comment?: string;
  activityReceptionDetails: ActivityReceptionDetail[];
}
