/*
 * File: global.actions.ts
 * Project: galilea-centro
 * File Created: Thursday, 10th October 2019 11:16:33 am
 * Author: Hector Piñero (hector@inventures.cl)
 * -----
 * Copyright 2019 - 2019 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { createAction, props } from '@ngrx/store';
import { Orientation } from '@app/animations';

export const ChangeAnimation = createAction('[Global] Animation Change', props<{ animation: Orientation }>());

export const ResetAnimation = createAction('[Global] Reset Animation');

export const AnimateNext = createAction('[Global] Animation Next');

export const AnimatePrev = createAction('[Global] Animate Prev');

export const ChangeLoader = createAction('[Global] Change Loader', props<{ loader: boolean }>());

export const ShowLoader = createAction('[Global] Show Loader');

export const HideLoader = createAction('[Global] Hide Loader');

export const ShowConcurrentLoader = createAction('[Global] Show ConcurrentLoader');

export const HideConcurrentLoader = createAction('[Global] Hide ConcurrentLoader');

export const setBranch = createAction('[Global] Set Branch', props<{ id: number }>());

export const setUserPermissions = createAction('[Global] Set User Permissions', props<{ permissions: string[] }>());

export const resetSelections = createAction('[Global] Reset Branch & User Role');
