<div class="default-modal confirm-message-modal">
  <h2 mat-dialog-title>
    {{ data.title }}
  </h2>
  <div
    mat-dialog-content
    [ngClass]="{
      textCenter: data.centered
    }"
  >
    <div class="content" *ngFor="let line of data.content">{{ line }}</div>
    <div class="confirm-buttons">
      <button class="btn-secondary" (click)="close()">
        {{ data.decline }}
      </button>
      <button mat-raised-button class="btn-primary" (click)="approve()">
        {{ data.accept }}
      </button>
    </div>
  </div>
</div>
