<mat-card [ngClass]="size === 'double' ? 'double' : 'simple'">
  <p>{{ title }}</p>
  <input
    [ngClass]="{ 'is-invalid': isInvalid }"
    matInput
    value=""
    [ngModel]="current"
    (ngModelChange)="selectionChange($event)"
    [placeholder]="placeholder"
    #input
  />
</mat-card>
