import { Component, OnInit, Input } from '@angular/core';
import { CardSize } from '../form-card/form-card.component';

@Component({
  selector: 'app-one-column-static-card',
  templateUrl: './one-column-static-card.component.html',
  styleUrls: ['./one-column-static-card.component.scss'],
})
export class OneColumnStaticCardComponent implements OnInit {
  @Input() title: string;
  @Input() body: string;
  @Input() fullDisplay?: boolean;
  @Input() icon?: string;
  @Input() size: CardSize = 'simple';
  constructor() {}

  ngOnInit(): void {}

  isFullDisplay(): boolean {
    return this.fullDisplay;
  }
}
