/*
 * File: ticket.ts
 * Project: galilea-centro
 * File Created: Wednesday, 9th October 2019 2:59:58 pm
 * Author: Hector Piñero (hector@inventures.cl)
 * -----
 * Copyright 2019 - 2019 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Contractors, SimpleContractor } from './contractor';
import { MaterialItems, Materials, Material } from './materialItems';
import { ActivityType, ActivityForTicket } from './activity';
import { PurchaseOrder as PO } from '@models/oc';
import { BudgetGroup } from './budgetGroup';
import { Provider } from '@models/provider';
import { IngressInfo } from './ingress';
import { Budget } from './budget';
import { UserInfo } from './user';
import { TicketStatus } from './ticketStatus.enum';

// eslint-disable-next-line no-shadow
export enum TicketType {
  house = 'Vivienda',
  urba = 'Urbanización',
  other = 'Otro',
}

export type PartialDeliveryReason = 'Quiebre' | 'No corresponde' | 'Otro';

export class PartialDeliveryReasonTypes {
  list = ['Quiebre', 'No corresponde', 'Otro'];
  stockBreak: PartialDeliveryReason = 'Quiebre';
  notApplicable: PartialDeliveryReason = 'No corresponde';
  other: PartialDeliveryReason = 'Otro';
}

export type OverrunReason = 'Robo' | 'Mala Ejecución' | 'Mala Cubicación' | 'Redondeo' | 'Otro';

export class OverrunReasonTypes {
  list = ['Robo', 'Mala Ejecución', 'Mala Cubicación', 'Redondeo', 'Otro'];
  stole: OverrunReason = 'Robo';
  execution: OverrunReason = 'Mala Ejecución';
  cubication: OverrunReason = 'Mala Cubicación';
  roundup: OverrunReason = 'Redondeo';
  other: OverrunReason = 'Otro';
}

export interface Explanation {
  reason: PartialDeliveryReason | OverrunReason;
  comment: string;
}

export interface ExplanationModalData {
  title: string;
  explanation: Explanation;
  reasons: string[];
}

export interface DigitalTicket {
  selectedContractor: Contractors;
  selectedActivity: MaterialItems;
  selectedBudgetGroup: BudgetGroup;
  selectedBudgets: Budget[];
  selectedMaterials: Materials[];
  explanation?: Explanation;
  type?: TicketType;
  urbaActivityQuantity?: number;
}

/* NEW MODEL FOR GRAVEL */
export interface DigitalTicketNew {
  provider: Partial<Provider>;
  material: Material;
  contractor: SimpleContractor;
  activityType: ActivityType;
  activity: ActivityForTicket;
  budgetGroup: BudgetGroup;
  budgets: Budget[];
  po: PO;
  details: IssueTicketDetail[];
  urbaActivityQuantity: number;
  ingressInfo: IngressInfo;
}

export interface TicketDetail {
  material: {
    id: number;
    name: string;
    deliveryFormat: {
      id: number;
      name: string;
      conversion: number;
    };
    stock?: number;
  };
  requestedQuantityConverted: number;
  deliveredQuantityConverted: number;
  maxQuantityConverted: number;
  quantity?: number;
  overload?: number;
  budgetedQuantity?: number;
  extraQuantity?: number;
  budget: {
    id: number;
    name: string;
    budgetGroup: {
      id: number;
      name: string;
    };
    phase?: {
      name: string;
      project: {
        name: string;
      };
    };
  };
}

export interface TicketComment {
  user: UserInfo;
  role?: string; // TODO Backward compatibility. Delete after some time
  statusChange?: TicketStatus;
  reason: string;
  comment: string;
  timestamp: string;
  edition?: boolean;
}

export interface IssuedTicket {
  id: number;
  activity: { id: string; name: string; type?: string };
  status: string;
  user: { name: string };
  deliveryPerson: { name: string };
  picker: string;
  contractor: { id: string; name: string };
  overrun: boolean;
  overrunReason: string;
  overrunComment: string;
  ticketDetails: TicketDetail[];
  issueDate: string;
  expiryDate: string;
  approvalDate: string;
  deliveryDate: string;
  branch: {
    name: string;
  };
  comments?: TicketComment[];
  approvedBy: { name: string };
  attachmentURL?: string;
}
export interface TicketProposedBudget {
  quantity: number;
  materialId: number;
  budgetId: number;
  metric: string;
  deliveryQuantity: number;
  unitQuantity: number;
  unitQuantityConverted: number;
  deliveryFormat: {
    id: number;
    name: string;
    conversion: number;
  };
  materialName: string;
  material: Materials;
}

export interface IssuedTicketSummary {
  id: number;
  ticketId?: number;
  ticket: IssuedTicket;
  branch: {
    id: number;
    name: string;
  };
  activity: {
    id: string;
    name: string;
    quantities?: {
      cubicatedQuantity: number;
    }[];
  };
  autoApproveMessage: string[];
  issueDate: Date;
  issuingUser: {
    id: number;
    name: string;
  };
  contractor: {
    id: number;
    name: string;
  };
  picker: string;
  overrunReason: string;
  overrunComment: string;
  rejectionComment: string;
  status: string;
  materialSummary: MaterialSummary[];
  budgets: Budget[];
  budgetGroup: BudgetGroup;
  comments?: TicketComment[];
}

export interface MaterialSummary {
  material: {
    id: number;
    name: string;
    displayName: string;
    measurement: string;
    deliveryFormat: {
      name: string;
    };
    quantificationMetric: string;
  };
  requestedQuantity: number;
  deliveredQuantity: number;
  requestedQuantityConverted: number;
  deliveredQuantityConverted: number;
  extraQuantityConverted: number;
  extraQuantity: number;
  unitQuantityConverted: number;
  unitQuantity: number;
  maxQuantityConverted?: number;
}

export interface IssueTicketDetail {
  materialId: number;
  budgetId: number;
  quantity: number;
  budgetedQuantity: number;
  maxQuantity: number;
  extraQuantity: number;
  overload: number;
}

export type UpdateTicketStatusParams = { id: number; status: TicketStatus; comment?: string };
