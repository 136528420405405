/*
 * File: supervisor-routing-order.ts
 * Project: galilea-centro
 * File Created: Monday, 20th April 2020 8:47:17 pm
 * Author: Kevin Johnson (kjjohnson@uc.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { ActivityType } from '@models/activity';
import { ROUTES } from '@app/views/mobile/routes.enum';

// route = <role>/<section>/<order[i]>
const mobileRoutesOrder = {
  ticket: {
    // section
    [ActivityType.house]: [
      // flow type
      { name: ROUTES.mobile.commonCreation.contractor.path }, // order[i]
      { name: ROUTES.mobile.commonCreation.stage.path },
      { name: ROUTES.mobile.commonCreation.activity.path },
      { name: ROUTES.mobile.commonCreation.lot.path },
      { name: ROUTES.mobile.ticketCreation.selectMaterials.path },
      { name: ROUTES.mobile.ticketCreation.materials.path },
      { name: ROUTES.mobile.commonCreation.summary.path },
    ],
    [ActivityType.urba]: [
      { name: ROUTES.mobile.commonCreation.contractor.path },
      { name: ROUTES.mobile.ticketCreation.urbanBudget.path },
      { name: ROUTES.mobile.ticketCreation.urbanActivity.path },
      { name: ROUTES.mobile.ticketCreation.selectMaterials.path },
      { name: ROUTES.mobile.ticketCreation.materials.path },
      { name: ROUTES.mobile.commonCreation.summary.path },
    ],
    [ActivityType.building]: [
      { name: ROUTES.mobile.commonCreation.contractor.path },
      { name: ROUTES.mobile.ticketCreation.urbanBudget.path },
      { name: ROUTES.mobile.ticketCreation.urbanActivity.path },
      { name: ROUTES.mobile.ticketCreation.selectMaterials.path },
      { name: ROUTES.mobile.ticketCreation.materials.path },
      { name: ROUTES.mobile.commonCreation.summary.path },
    ],
    [ActivityType.other]: [
      { name: ROUTES.mobile.commonCreation.contractor.path },
      { name: ROUTES.mobile.ticketCreation.otherBudget.path },
      { name: ROUTES.mobile.ticketCreation.otherActivity.path },
      { name: ROUTES.mobile.ticketCreation.selectMaterials.path },
      { name: ROUTES.mobile.ticketCreation.materials.path },
      { name: ROUTES.mobile.commonCreation.summary.path },
    ],
  },
  aridos: {
    //TODO documentar
    [ActivityType.house]: [
      { name: ROUTES.mobile.gravelReception.provider.path, store: ['provider'] },
      { name: ROUTES.mobile.gravelReception.material.path, store: ['material'] },
      { name: ROUTES.mobile.commonCreation.contractor.path, store: ['contractor', 'activityType'] },
      { name: ROUTES.mobile.commonCreation.stage.path, store: ['budgetGroup'] },
      { name: ROUTES.mobile.commonCreation.activity.path, store: ['activity'] },
      { name: ROUTES.mobile.commonCreation.lot.path, store: ['budgets'] },
      { name: ROUTES.mobile.gravelReception.po.path, store: ['po'], group: 1 },
      {
        name: ROUTES.mobile.gravelReception.selectPo.path,
        store: ['po'],
        group: 1,
        optional: true,
        whenNavigating: 'next',
      },
      { name: ROUTES.mobile.gravelReception.quantity.path, store: ['details'] },
      { name: ROUTES.mobile.gravelReception.ingress.path, store: ['ingressInfo'] },
      { name: ROUTES.mobile.commonCreation.summary.path, store: [] },
    ],
    [ActivityType.urba]: [
      { name: ROUTES.mobile.gravelReception.provider.path, store: ['provider'] },
      { name: ROUTES.mobile.gravelReception.material.path, store: ['material'] },
      { name: ROUTES.mobile.commonCreation.contractor.path, store: ['contractor', 'activityType'] },
      { name: ROUTES.mobile.gravelReception.budget.path, store: ['budgets', 'budgetGroup'] },
      { name: ROUTES.mobile.commonCreation.activity.path, store: ['activity'] },
      { name: ROUTES.mobile.gravelReception.po.path, store: ['po'], group: 1 },
      {
        name: ROUTES.mobile.gravelReception.selectPo.path,
        store: ['po'],
        group: 1,
        optional: true,
        whenNavigating: 'next',
      },
      { name: ROUTES.mobile.gravelReception.quantity.path, store: ['details'] },
      { name: ROUTES.mobile.gravelReception.ingress.path, store: ['ingressInfo'] },
      { name: ROUTES.mobile.commonCreation.summary.path, store: [] },
    ],
    [ActivityType.building]: [
      { name: ROUTES.mobile.gravelReception.provider.path, store: ['provider'] },
      { name: ROUTES.mobile.gravelReception.material.path, store: ['material'] },
      { name: ROUTES.mobile.commonCreation.contractor.path, store: ['contractor', 'activityType'] },
      { name: ROUTES.mobile.gravelReception.budget.path, store: ['budgets', 'budgetGroup'] },
      { name: ROUTES.mobile.commonCreation.activity.path, store: ['activity'] },
      { name: ROUTES.mobile.gravelReception.po.path, store: ['po'], group: 1 },
      {
        name: ROUTES.mobile.gravelReception.selectPo.path,
        store: ['po'],
        group: 1,
        optional: true,
        whenNavigating: 'next',
      },
      { name: ROUTES.mobile.gravelReception.quantity.path, store: ['details'] },
      { name: ROUTES.mobile.gravelReception.ingress.path, store: ['ingressInfo'] },
      { name: ROUTES.mobile.commonCreation.summary.path, store: [] },
    ],
  },
};
mobileRoutesOrder.aridos[ActivityType.other] = mobileRoutesOrder.aridos[ActivityType.urba];

export default mobileRoutesOrder;
