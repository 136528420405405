import { Component, OnInit, Output, Input, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { SearchService } from '@app/services/search.service';
import Fuse from 'fuse.js';
import { Selection } from '../select/select.component';

@Component({
  selector: 'app-one-column-dropdown-card',
  templateUrl: './one-column-dropdown-card.component.html',
  styleUrls: ['./one-column-dropdown-card.component.scss'],
})
export class OneColumnDropdownCardComponent implements OnInit, OnChanges {
  @Input() selections: Selection[];
  @Input() attr: string;
  @Input() title: string;
  @Input() currentValue: string;
  @Input() disabled?: boolean;
  @Input() searchable?: boolean;
  @Input() icon?: string;
  @Input() iconCallback?: () => void;
  @Input() iconTooltip?: string;
  @Input() isInvalid?: boolean;
  @Input() panelWidth? = 'max-content';
  @Input() enableClearButton?: boolean;
  @Output() selected: EventEmitter<{ selection: string; attr: string }> = new EventEmitter();
  searcher: Fuse<Selection>;
  searchOptions = {
    keys: [
      { name: 'name', weight: 0.8 },
      { name: 'optional', weight: 0.2 },
    ],
  };
  filteredSelections;
  temp = '';

  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    if (this.searchable) {
      this.searcher = this.searchService.updateSearcher({ objects: this.selections, options: this.searchOptions });
      this.filteredSelections = this.selections;
      if (this.currentValue) {
        this.temp = this.selections.find((e) => e.value === this.currentValue)?.value || '';
      }
    }
    if (!this.currentValue) {
      this.currentValue = '';
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    //To fill or get empty the selector if data changes
    this.temp = this.selections.find((e) => e.value === this.currentValue)?.value || '';
    if (changes.selections) {
      this.searcher = this.searchService.updateSearcher({ objects: this.selections, options: this.searchOptions });
      this.filteredSelections = this.selections;
    }
  }
  selectionChange(value: string): void {
    this.selected.emit({ selection: value, attr: this.attr });
  }
  onClear() {
    this.selected.emit({ selection: null, attr: this.attr });
  }
  displayFn(value?: number): string {
    return this.selections.filter((s) => String(s.value) === String(value))[0]?.name;
  }
  search(event: string): void {
    const { results, searching } = this.searchService.search({
      text: event,
      instance: this.searcher,
    });
    this.filteredSelections = searching ? results : this.selections;
  }
}
