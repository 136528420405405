<mat-card>
  <p>{{ title }}</p>
  <app-upload
    *ngIf="!disabled || url !== ''"
    [disabled]="disabled"
    [meta]="meta"
    (result)="emitResultUpload($event)"
    [url]="url"
  ></app-upload>
  <div *ngIf="disabled && url === ''">No se puede subir archivo</div>
</mat-card>
