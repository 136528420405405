import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'niceFloor' })
export class NiceFloorPipe implements PipeTransform {
  /**
   *
   * @param value
   * @returns {number}
   */
  transform(value: number): number {
    return Math.sign(value) * Math.floor(Math.round(Math.abs(value) * 100) / 100);
  }
}
