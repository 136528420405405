import { User } from './user';
import { WorkSubpackage } from './workSubpackage';

// eslint-disable-next-line no-shadow
export enum WorkPackageType {
  HOUSE = 'HOUSE',
  URBANIZATION = 'URBANIZATION',
  OTHER = 'OTHER',
  HOUSE_REPAIR = 'HOUSE_REPAIR',
  URBANIZATION_REPAIR = 'URBANIZATION_REPAIR',
  OTHER_REPAIR = 'OTHER_REPAIR',
  BUILDING = 'BUILDING',
  BUILDING_REPAIR = 'BUILDING_REPAIR',
  SERVICE = 'SERVICE',
  LABOR = 'LABOR',
}

export interface WorkPackage {
  id: number;
  name: string;
  type: WorkPackageType;
  enabled: boolean;
  workSubpackages?: WorkSubpackage[];
  createdAt: string;
  createdBy: User;
  updatedAt: string;
  updatedBy: User;
}
