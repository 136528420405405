import { Branch } from './branch';
import { Budget } from './budget';
import { BudgetGroup } from './budgetGroup';
import { Contract } from './contractor';
import { PurchaseOrder } from './purchaseOrder';
import { Provider } from './provider';
import { ActivityReception } from './reception';
import { User } from './user';

export interface PaymentStatus {
  // ID in DB"
  id: number;
  // Reference to the current contract at the time of payment status creation"
  contract: Contract;
  // Reference to the branch where the payment status was performed"
  branch: Branch;
  // Reference to the contractor"
  provider: Partial<Provider>;
  // Current status of the payment status"
  status: PaymentStatusStatus;
  // Type of the payment status
  type: PaymentStatusType;
  // Timestamp of when this payment status was paid"
  paidAt: string;
  // Reference to the user who approved the payment"
  paidBy: User;
  // List of additional items being paid"
  paymentStatusAdditionals: PaymentStatusAdditionals[];
  // List of additional items being paid, grouped by budgetGroup
  groupedAdditionals: PaymentStatusGroupedAdditional[];
  // Retentions movements associated to this payment status"
  paymentRetentionMovements: PaymentStatusRetentionMovement[];
  // List of activity receptions linked to this payment status"
  activityReceptions: ActivityReception[];
  // General comments for the payment status"
  comment: string;
  // Purchase order associated to this payment status"
  purchaseOrder: PurchaseOrder;
  // List of activity receptions that have been linked to this payment status and never manually unlinked"
  linkedReceptions: ActivityReception[];
  // Reference to the user who created this payment status"
  createdBy: User;
  // Reference to the user who last updated this payment status"
  updatedBy: User;
  // Timestamp of entity creation"
  createdAt: string;
  // Timestamp of last entity update"
  updatedAt: string;
  // If purchase order should be created without IVA
  isExempt: boolean;
  // Fortnight selected
  fortnight: Fortnight;

  /**
   * If present, the total amount involved in the payment status if all items were to be paid
   *
   * @type {number}
   * @memberof PaymentStatus
   */
  estimatedTotalAmount?: number;

  /**
   * If present, total amount in items in pending status
   *
   * @type {number}
   * @memberof PaymentStatus
   */
  pendingAmount?: number;

  /**
   * If present, total amount in items in approved or accepted status
   *
   * @type {number}
   * @memberof PaymentStatus
   */
  approvedAmount?: number;
}

export interface PaymentStatusGroupedAdditional {
  id: string;
  reason: AdditionalReasons;
  status: string;
  description: string;
  budgetGroup: BudgetGroup;
  totalAmount: number;
  additionals: PaymentStatusAdditionals[];
}

export interface PaymentStatusGroupedAdditional {
  id: string;
  reason: AdditionalReasons;
  status: string;
  description: string;
  budgetGroup: BudgetGroup;
  totalAmount: number;
  additionals: PaymentStatusAdditionals[];
}

export interface PaymentStatusAdditionals {
  id: number;
  amount: number;
  status: string;
  reason: AdditionalReasons;
  description: string;
  budgetGroup: BudgetGroup;
  budget: Budget;
}

export interface Fortnight {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
}

export interface PaymentStatusRetentionMovement {
  id: number;
}

// eslint-disable-next-line no-shadow
export enum PaymentStatusStatus {
  /**
   * Payment status is a draft
   */
  DRAFT = 'DRAFT',
  /**
   * Payment status has been rejected and won't be paid
   */
  REJECTED = 'REJECTED',
  /**
   * Payment status is waiting for review
   */
  PENDING = 'PENDING',
  /**
   * Payment status has been accepted and will be paid
   */
  ACCEPTED = 'ACCEPTED',
  /**
   * Payment status is in final payment review step
   */
  PAYMENT_REVIEW = 'PAYMENT_REVIEW',
  /**
   * Payment status has been paid
   */
  PAID = 'PAID',
  /**
   * Invoice has been created
   */
  SENT_TO_SAP = 'SENT_TO_SAP',
  /**
   * Invoice is being create
   */
  GENERATING_INVOICE = 'GENERATING_INVOICE',
  /**
   * A fatal error ocurred during invoice generation and needs manual intervention
   */
  INVOICE_ERROR = 'INVOICE_ERROR',
}

export function translatePaymentStatusStatus(s: string): PaymentStatusStatus {
  switch (s?.toUpperCase()) {
    case 'DRAFT':
      return PaymentStatusStatus.DRAFT;
    case 'REJECTED':
      return PaymentStatusStatus.REJECTED;
    case 'PENDING':
      return PaymentStatusStatus.PENDING;
    case 'ACCEPTED':
      return PaymentStatusStatus.ACCEPTED;
    case 'PAYMENT_REVIEW':
      return PaymentStatusStatus.PAYMENT_REVIEW;
    case 'PAID':
      return PaymentStatusStatus.PAID;
    case 'SENT_TO_SAP':
      return PaymentStatusStatus.SENT_TO_SAP;
    case 'GENERATING_INVOICE':
      return PaymentStatusStatus.GENERATING_INVOICE;
    case 'INVOICE_ERROR':
      return PaymentStatusStatus.INVOICE_ERROR;
    default:
      return null;
  }
}

// eslint-disable-next-line no-shadow
export enum PaymentStatusType {
  LEGACY = 'LEGACY',
  PROGRESS = 'PROGRESS',
  ADDITIONAL = 'ADDITIONAL',
  RETENTION = 'RETENTION',
}

// eslint-disable-next-line no-shadow
export enum PaymentStatusNames {
  DRAFT = 'Borrador',
  REJECTED = 'Rechazado',
  PENDING = 'Por revisar',
  ACCEPTED = 'Aceptado',
  PAYMENT_REVIEW = 'Enviado a gerencia',
  PAID = 'OC esperando factura',
  SENT_TO_SAP = 'Enviado a SAP',
  GENERATING_INVOICE = 'Generando factura',
  INVOICE_ERROR = 'Error en generar factura',
}

// eslint-disable-next-line no-shadow
export enum AdditionalStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  ACCEPTED = 'ACCEPTED',
  PAID = 'PAID',
}

export const translateAdditionalStatus = (status: string): AdditionalStatus => {
  switch (status) {
    case 'PENDING':
      return AdditionalStatus.PENDING;
    case 'REJECTED':
      return AdditionalStatus.REJECTED;
    case 'ACCEPTED':
      return AdditionalStatus.ACCEPTED;
    case 'PAID':
      return AdditionalStatus.PAID;
    default:
      return null;
  }
};

// eslint-disable-next-line no-shadow
export enum AdditionalStatusNames {
  PENDING = 'Pendiente',
  REJECTED = 'Rechazado',
  ACCEPTED = 'Liberado',
  PAID = 'Pagado',
}

// eslint-disable-next-line no-shadow
export enum AdditionalReasons {
  /**
   * Payment from the retentions account
   */
  RETENTION_PAYMENT = 'RETENTION_PAYMENT',
  /**
   * General discount
   */
  DISCOUNT = 'DISCOUNT',
  /**
   * Extra labor performed
   */
  EXTRA_ACTIVITY = 'EXTRA_ACTIVITY',
  /**
   * Discount due to labor reasons, such as safety faults
   */
  LABOR_DISCOUNT = 'LABOR_DISCOUNT',
  /**
   * Return of a labor discount
   */
  LABOR_DISCOUNT_RETURN = 'LABOR_DISCOUNT_RETURN',
  /**
   * Discount due to a previosly paid reception that shouldn't have been accepted
   */
  BAD_RECEPTION = 'BAD_RECEPTION',
  /**
   * Payment advance
   */
  ADVANCE = 'ADVANCE',
  /**
   * Recovery of a payment advance
   */
  ADVANCE_RECOVERY = 'ADVANCE_RECOVERY',
  /**
   * Learning bonus
   */
  LEARNING_BONUS = 'LEARNING_BONUS',
  /**
   * Category for anything else
   */
  OTHER_BONUSES = 'OTHER_BONUSES',
  OTHER_DISCOUNT = 'OTHER_DISCOUNT',
  /**
   * Transport expenses
   */
  TRANSPORT = 'TRANSPORT',
  /**
   * Food expenses
   */
  FOOD = 'FOOD',
  /**
   * Housing expenses
   */
  HOUSING = 'HOUSING',
  /**
   * Security expenses
   */
  SECURITY = 'SECURITY',
  /**
   * Major equipment rental
   */
  MAJOR_EQUIPMENT_RENTAL = 'MAJOR_EQUIPMENT_RENTAL',
  /**
   * Minor Repairs. Temporally reason until better implementation.
   */
  MINOR_REPAIRS = 'MINOR_REPAIRS',
  PPE_DISCOUNT = 'PPE_DISCOUNT',
  TOOLS_DISCOUNT = 'TOOLS_DISCOUNT',
  RISK_MANAGEMENT_DISCOUNT = 'RISK_MANAGEMENT_DISCOUNT',
  AFTER_SALE_DISCOUNT = 'AFTER_SALE_DISCOUNT',
  SECURITY_DISCOUNT = 'SECURITY_DISCOUNT',
}

// eslint-disable-next-line no-shadow
export enum AdditionalReasonNames {
  RETENTION_PAYMENT = 'Pago de retención',
  DISCOUNT = 'Descuento',
  ADVANCE = 'Anticipo',
  ADVANCE_RECOVERY = 'Recupero de anticipo',
  EXTRA_ACTIVITY = 'Obra Extra',
  LABOR_DISCOUNT = 'Descuento laboral',
  LABOR_DISCOUNT_RETURN = 'Devolución de descuentos laborales',
  BAD_RECEPTION = 'Partida mal recibida',
  LEARNING_BONUS = 'Bono aprendizaje',
  OTHER_BONUSES = 'Premio por rendimiento',
  OTHER_DISCOUNT = 'Otro descuento',
  TRANSPORT = 'Transporte',
  FOOD = 'Colación',
  HOUSING = 'Hospedaje',
  SECURITY = 'Seguridad',
  MAJOR_EQUIPMENT_RENTAL = 'Arriendo equipos mayores',
  MINOR_REPAIRS = 'Sobrecarga partidas',
  PPE_DISCOUNT = 'Descuento por EPP',
  TOOLS_DISCOUNT = 'Descuento herramientas',
  RISK_MANAGEMENT_DISCOUNT = 'Descuento multa prevención',
  AFTER_SALE_DISCOUNT = 'Descuento postventa',
  SECURITY_DISCOUNT = 'Descuento seguridad',
}

// eslint-disable-next-line no-shadow
export enum ReasonType {
  POSITIVE_REASON = 'POSITIVE_REASON',
  NEGATIVE_REASON = 'NEGATIVE_REASON',
}
