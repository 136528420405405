import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-one-column-upload-card',
  templateUrl: './one-column-upload-card.component.html',
  styleUrls: ['./one-column-upload-card.component.scss'],
})
export class OneColumnUploadCardComponent implements OnInit {
  @Input() attr: string;
  @Input() title: string;
  @Input() meta: string;
  @Input() url: string;
  @Input() disabled?: boolean;
  @Output() result: EventEmitter<{ url: string; attr: string }> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  emitResultUpload(event) {
    if (!this.disabled) this.result.emit({ attr: this.attr, url: event.url });
  }
}
