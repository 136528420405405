import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

export const routeAnimationTime = 300;

export const animateNone = [query(':enter, :leave', [], { optional: true })];
export const animateBackward = [
  style({ position: 'relative' }),
  query(
    ':enter, :leave',
    [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ],
    { optional: true },
  ),
  query(':enter', [style({ left: '-100%' })], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':enter', [animate(`${routeAnimationTime}ms ease-out`, style({ left: '0%' }))], { optional: true }),
    query(':leave', [animate(`${routeAnimationTime}ms ease-out`, style({ left: '100%' }))], { optional: true }),
  ]),
  query(':enter', animateChild(), { optional: true }),
];

export const animateForward = [
  style({ position: 'relative' }),
  query(
    ':enter, :leave',
    [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
      }),
    ],
    { optional: true },
  ),
  query(':enter', [style({ left: '100%' })], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':enter', [animate(`${routeAnimationTime}ms ease-out`, style({ left: '0%' }))], { optional: true }),
    query(':leave', [animate(`${routeAnimationTime}ms ease-out`, style({ left: '-100%' }))], { optional: true }),
  ]),
  query(':enter', animateChild(), { optional: true }),
];

// eslint-disable-next-line no-shadow
export enum Orientation {
  prev = 'prev',
  next = 'next',
  void = 'void',
}

export const slideAnimation = trigger('routerTransition', [
  transition(`${Orientation.void} => ${Orientation.next}`, animateForward),
  transition(`${Orientation.void} => ${Orientation.prev}`, animateBackward),
  transition(`${Orientation.next} => ${Orientation.void}`, animateNone),
  transition(`${Orientation.prev} => ${Orientation.void}`, animateNone),
]);
