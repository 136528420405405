export const getFromLocalStorage = <T = any>(key: string): T => {
  return JSON.parse(localStorage.getItem(key));
};

export const saveToLocalStorage = <T = any>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromSessionStorage = <T = any>(key: string): T => {
  return JSON.parse(sessionStorage.getItem(key));
};

export const saveToSessionStorage = <T = any>(key: string, value: T): void => {
  sessionStorage.setItem(key, JSON.stringify(value));
};
