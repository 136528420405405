import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe as AngularDecimalPipe } from '@angular/common';
import { environment } from '@env/environment';

export type DecimalType = 'r' | 'p' | 'q' | 'pq';

/** WRAPPER FOR ANGULAR DECIMAL PIPE */
@Pipe({
  name: 'niceDecimals',
})
export class NiceDecimalsPipe implements PipeTransform {
  DP = new AngularDecimalPipe('es-CL');
  /*
   * Examples:
   * transform(10.45678, 'p')  --> 10.46
   * transform(10.45678, 'q')  --> 10.4568
   * transform(10.45678, 'pq') --> 10
   */
  transform(value: number, type: DecimalType): string {
    if (!value && value !== 0) return '';
    return this.DP.transform(value, environment.decimals[type + 'Info']);
  }
}
