import { format } from 'date-fns';

export const getCompactNow = (): string => {
  const now = new Date();
  return format(now, 'yyyyMMddHHmmss');
};

export const buildFileName = (
  directories: (string | number)[],
  filenameSuffix?: string | number,
  ext?: string,
): string => {
  const filename = [...directories, filenameSuffix].filter(Boolean).join('_');
  const extension = ext ? `.${ext}` : '';
  return `${directories.join('/')}/${filename}${extension}`;
};
