import {
  Component,
  OnInit,
  AfterViewInit,
  EventEmitter,
  QueryList,
  Output,
  Input,
  ViewChildren,
  ElementRef,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { CardSize } from '../form-card/form-card.component';
@Component({
  selector: 'app-one-column-card',
  templateUrl: './one-column-card.component.html',
  styleUrls: ['./one-column-card.component.scss'],
})
export class OneColumnCardComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() attr: string;
  @Input() title: string;
  @Output() selected: EventEmitter<{ attr: string; value: string }> = new EventEmitter();
  @Input() current: string;
  @Input() pattern: string;
  @Input() regex: RegExp;
  @Input() placeholder: string;
  @ViewChildren('input') childrenComponent: QueryList<any>;
  current$: BehaviorSubject<string>;
  inputRef: ElementRef;
  private unsubscribe$ = new Subject<void>();
  @Input() isInvalid = false;
  @Input() size: CardSize = 'simple';

  constructor() {}

  ngOnInit(): void {
    if (this.pattern) this.regex = new RegExp(this.pattern);
    this.current$ = new BehaviorSubject<string>(this.current);
    this.current$.pipe(debounceTime(1000), distinctUntilChanged(), takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.selected.emit({ value, attr: this.attr });
    });
  }

  ngAfterViewInit(): void {
    this.inputRef = this.childrenComponent.first;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.current$ && changes?.current?.currentValue !== this.current$.value) this.current$.next(this.current);
  }

  selectionChange(value: string): void {
    if (this.regex) {
      if (this.regex.test(value)) {
        this.current$.next(value);
      } else {
        this.inputRef.nativeElement.value = this.current$.value;
      }
      return;
    }
    this.current$.next(value);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
