/*
 * File: gravel.actions.ts
 * Project: galilea-centro
 * File Created: Monday, 18th May 2020 6:27:55 pm
 * Author: Kevin Johnson (kjjohnson@uc.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { createAction, props } from '@ngrx/store';

import { ActivityType, ActivityForTicket } from '@models/activity';
import { IssueTicketDetail as Detail } from '@models/ticket';
import { SimpleContractor } from '@models/contractor';
import { BudgetGroup } from '@models/budgetGroup';
import { PurchaseOrder as PO } from '@models/oc';
import { Material } from '@models/materialItems';
import { IngressInfo } from '@models/ingress';
import { Provider } from '@models/provider';
import { Budget } from '@models/budget';

export const ResetSelections = createAction('[Gravel] Reset Selections');

export const SaveProvider = createAction('[Gravel] Set Provider', props<{ provider: Partial<Provider> }>());

export const SaveMaterial = createAction('[Gravel] Set Material', props<{ material: Material }>());

export const SaveContractor = createAction('[Gravel] Set Contractor', props<{ contractor: SimpleContractor }>());

export const SaveActivityType = createAction('[Gravel] Set Activity Type', props<{ activityType: ActivityType }>());

export const SaveActivity = createAction('[Gravel] Set Activity', props<{ activity: ActivityForTicket }>());

export const SaveBudgetGroup = createAction('[Gravel] Set BudgetGroup', props<{ budgetGroup: BudgetGroup }>());

export const SaveBudgets = createAction('[Gravel] Set Budgets', props<{ budgets: Budget[] }>());

export const SavePO = createAction('[Gravel] Set PO', props<{ po: PO }>());

export const SaveDetails = createAction('[Gravel] Set Details', props<{ details: Detail[] }>());

export const SaveUrbaActivityQuantity = createAction(
  '[Gravel] Set UrbaActivityQuantity',
  props<{ urbaActivityQuantity: number }>(),
);

export const SaveIngressInfo = createAction('[Gravel] Set Ingress Info', props<{ ingressInfo: IngressInfo }>());
