<div class="quantity-form" [ngClass]="{ warning: isWarning() }">
  <button
    *ngIf="editable"
    mat-raised-button
    [disabled]="isRemoveButtonDisabled()"
    (click)="onDecrementButtonClick()"
    (mousedown)="decrementInterval()"
    (mouseup)="stopInterval()"
    (mouseleave)="stopInterval()"
    data-test="app-number-input-decrement"
  >
    <i class="material-icons">remove</i>
  </button>
  <mat-form-field class="quantity-input" [ngClass]="{ warning: isWarning() }" *ngIf="editable">
    <input
      matInput
      #input
      [ngModel]="value | number: pipeFormat"
      (change)="handleChangeDebounced(input.value)"
      (keyup)="handleChangeDebounced(input.value)"
      #m="ngModel"
      data-test="app-number-input-input"
    />
  </mat-form-field>
  <div class="quantity-input not-editable" *ngIf="!editable">
    {{ value | number: pipeFormat }}
  </div>

  <button
    *ngIf="editable"
    mat-raised-button
    [disabled]="isAddButtonDisabled()"
    (click)="onIncrementButtonClick()"
    (mousedown)="incrementInterval()"
    (mouseup)="stopInterval()"
    (mouseleave)="stopInterval()"
    data-test="app-number-input-increment"
  >
    <i class="material-icons">add</i>
  </button>

  <span class="warning-label">
    {{ warningMessage }}
  </span>
</div>
