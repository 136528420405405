import { commonEnvs } from './common';

export const environment = {
  ...commonEnvs,
  production: true,
  prerelease: false,
  enableLogger: false,
  auth: true,
  apiUrl: 'https://galileapro-api.herokuapp.com/graphql',
  firebaseConfig: {
    apiKey: 'AIzaSyBqe7bIvmz8baTVhztI_k42qEup_zoQgl0',
    authDomain: 'galilea-centro.firebaseapp.com',
    databaseURL: 'https://galilea-centro.firebaseio.com',
    projectId: 'galilea-centro',
    storageBucket: '',
    messagingSenderId: '959109318106',
    appId: '1:959109318106:web:010f7d68e4527ae70b6ce3',
  },
  actionCodeSettings: {
    // Your redirect URL
    url: 'https://galileapro.com/login',
    handleCodeInApp: true,
  },
  wsUrl: 'wss://galileapro-api.herokuapp.com/graphql',
  sentryEnv: 'production',
  emulatorUrl: '',
};
