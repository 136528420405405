import { NgModule } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/cache';
import { persistCache } from 'apollo3-cache-persist';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '@env/environment';
import { setContext } from '@apollo/client/link/context';
import { AuthService } from './services/auth.service';
import { ApolloLink, split } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { LoaderService } from './services/loader.service';
import { NetworkErrorService } from './services/network-error.service';
import { ErrorService } from './services/error.service';
import { OperationDefinitionNode } from 'graphql';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { GlobalService } from './services/global.service';
import * as idb from 'localstorage-idb-keyval';
import { GraphqlService } from './services/graphql.service';

import { inflate } from 'graphql-deduplicator';
import { SentryScopeService } from './services/sentryScope.service';
import { getFromLocalStorage, getFromSessionStorage } from './utils';
import { ErrorCode } from '@app/models';

@NgModule({
  exports: [HttpClientModule],
})
export class GraphQLModule {
  cache = new InMemoryCache();
  currentBranch = null;
  wsClient: SubscriptionClient = null;
  silentInternalError = new Set(['cannot_receive_more_than_pending_repetitions']);
  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private authFirebase: AuthService,
    private loader: LoaderService,
    private networkErrorModal: NetworkErrorService,
    private errorService: ErrorService,
    private globalService: GlobalService,
    private apolloService: GraphqlService,
    private sentryScope: SentryScopeService,
  ) {
    this.apollo.create({
      link: this.getLink(),
      cache: this.cache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    });
    this.buildCache().then(() => {
      this.apollo.removeClient();
      this.createApolloClient();
      authFirebase.addCallbackOnAuthStateChanged((user) => {
        !user && this.closeApolloWebSocketClient();
      });
      globalService.getBranch().subscribe((branch) => {
        if (!this.currentBranch) {
          this.currentBranch = branch;
          return;
        }
        this.apollo.client.stop();
        this.apollo.client.resetStore();
        this.closeApolloWebSocketClient();
      });
    });
  }

  closeApolloWebSocketClient = (): void => {
    if (this.wsClient) {
      this.wsClient.close(false, false);
    }
  };

  createApolloClient = (): void => {
    this.apollo.create({
      link: this.getLink(),
      cache: this.cache,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
      },
    });
    this.apolloService.lodaded$.next(true);
  };

  getLink = (): ApolloLink => {
    const auth = setContext(async () => {
      const token = await this.authFirebase.getJWT();
      if (token === '') {
        return { headers: {} };
      }
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    });

    const sentryLink = setContext((operation, context) => {
      const transactionId = this.sentryScope.transactionId;
      return {
        headers: {
          ...context.headers,
          'X-Transaction-ID': transactionId,
        },
      };
    });

    const branchLink = setContext((operation, context) => {
      const branchId = getFromSessionStorage('branchId') ?? getFromLocalStorage('branchId');
      if (!branchId) {
        return { headers: context.headers };
      }
      return {
        headers: {
          ...context.headers,
          'X-BranchId': String(branchId),
        },
      };
    });
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors || networkError) {
        this.loader.hideLoader();
      }
      if ((networkError as any)?.error?.errors?.some((error) => error.extensions.code === 'UNAUTHENTICATED')) {
        this.authFirebase.logout();
        return;
      }
      if (graphQLErrors) {
        const errorCode = graphQLErrors[0]?.extensions?.code as ErrorCode;
        const internalErrorMessage = graphQLErrors[0]?.message;
        if (!this.silentInternalError.has(internalErrorMessage))
          this.errorService.showError(errorCode, internalErrorMessage);
      }
      if (networkError) {
        this.networkErrorModal.showError();
      }
    });

    const inflateLink = new ApolloLink((operation, forward) => {
      return forward(operation).map((response) => {
        return inflate(response);
      });
    });

    const wsClient = new SubscriptionClient(environment.wsUrl, {
      reconnect: true,
      reconnectionAttempts: 50,
      lazy: true,
      timeout: 20000,
      connectionParams: async () => {
        const token = await this.authFirebase.getJWT();
        const branchId = getFromSessionStorage('branchId') ?? getFromLocalStorage('branchId');
        return {
          authorization: token ? `Bearer ${token}` : null,
          branchId,
        };
      },
    });

    this.wsClient = wsClient;
    const wsLink = new WebSocketLink(wsClient);

    const http = this.httpLink.create({ uri: environment.apiUrl });

    const apolloHttpLink = ApolloLink.from([errorLink, auth, branchLink, sentryLink, inflateLink, http]);

    const link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      apolloHttpLink,
    );
    return link;
  };

  buildCache(): Promise<void> {
    return persistCache({ cache: this.cache, storage: idb });
  }
}
