/*
 * File: materialItems.ts
 * Project: galilea-centro
 * File Created: Tuesday, 8th October 2019 4:53:17 pm
 * Author: Hector Piñero (hector@inventures.cl)
 * -----
 * Copyright 2019 - 2019 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { ActivityCategory } from './activityCategory';
import { Branch } from './branch';
import { MaterialPriceAgreement } from './priceAgreement';
import { PurchaseOrder } from './purchaseOrder';
import { User } from './user';
import { WorkPackage } from './workPackage';

// eslint-disable-next-line no-shadow
export enum EUnitTypes {
  un = 'UN',
  kg = 'KG',
  mt = 'MT',
  m2 = 'M2',
  m3 = 'M3',
  lt = 'LT',
}

export interface Materials {
  firstLetterFixed?: string;
  id: number;
  name: string;
  quantity: number;
  unit: string;
  formatName?: string;
  increment: number;
  maxQuantity?: number;
  unitQuantityConverted?: number;
  extraQuantityConverted?: number;
  overload?: number;
  lotQuantity?: number;
  nickName?: string;
  selected?: boolean;
  searched?: boolean;
  isGravel?: boolean;
  stock?: number;
}

export interface MaterialExtraInput {
  activityId: number;
  materialIds: number[];
  budgetIds: number[];
}
export interface MaterialExtraResponse {
  activityId: number;
  budgetId: number;
  materialId: number;
  extraQuantity: number;
  extraQuantityConverted: number;
  unitQuantity: number;
  unitQuantityConverted: number;
  activityQuantity: number;
}

export interface ShortMaterials {
  name: string;
  quantity: number;
  unit: string;
}

export interface MaterialItems {
  firstLetterFixed?: string;
  id: number;
  name: string;
  type: string;
  scope: string;
  specialty: {
    id: number;
    name: string;
  };
  group: string;
  items: Materials[];
  supervision: boolean;
  judgments: string[];
  activity?: string;
  order?: number;
  cubicationFormat: string;
  quantities: {
    cubicatedQuantity: number;
  };
  materials?: {
    id: number;
    name: string;
    nickname: string;
  }[];
  workPackage?: WorkPackage;
  activityCategory?: ActivityCategory;
}

// eslint-disable-next-line no-shadow
export enum MaterialType {
  MATERIAL = 'MATERIAL',
  SERVICE = 'SERVICE',
  OTHER = 'OTHER',
}

export interface DocumentedMaterialMovement {
  /*id: number;
  type: string;
  details: MaterialSummary[];
  comments: string;
  branch: {
    name: string;
  };
  metadata: {
    issuerName: string;
    declaredDate: string | number;
    purchaseOrder: string;
  };*/

  id: number;
  type: string;
  branch: Branch;
  details: MaterialSummary[];
  comments: string;
  metadata: {
    issuerName: string;
    declaredDate: string | number;
    purchaseOrder: string;
    documentNumber: number;
  };
  enabled: boolean;
  createdAt: Date;
  updatedAt: Date;
  attachmentURLs: string[];
  purchaseOrders: PurchaseOrder[];
  createdBy: User;
  editable: boolean;
}

export interface MaterialSummary {
  id: number;
  material: {
    name: string;
    measurement: string;
    deliveryFormat: {
      name: string;
    };
    displayName: string;
  };
  requestedQuantity: number;
  deliveredQuantity: number;
  requestedQuantityConverted: number;
  deliveredQuantityConverted: number;
  extraQuantityConverted: number;
  extraQuantity: number;
  quantityConverted?: number;
  overrunQuantity: number;
}

export interface DeliveryFormat {
  id: number;
  name: string;
  conversion?: number;
  baseUnit?: string;
}
export interface Material {
  id: number;
  name: string;
  displayName: string;
  nickName: string;
  measurement?: string;
  color?: string;
  side?: string;
  deliveryFormat: DeliveryFormat;
  materialFormats: DeliveryFormat[];
  priceAgreements: MaterialPriceAgreement[];
}
