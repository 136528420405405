import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CardSize } from '../form-card/form-card.component';

@Component({
  selector: 'app-two-column-static-card',
  templateUrl: './two-column-static-card.component.html',
  styleUrls: ['./two-column-static-card.component.scss'],
})
export class TwoColumnStaticCardComponent implements OnInit {
  @Input() title: string;
  @Input() column1: string;
  @Input() column2: string;
  @Input() icon: string;
  @Input() message: string;
  @Input() widths: [number, number] = [0.5, 0.5];
  @Input() size: CardSize = 'simple';
  @Output() clicked: EventEmitter<Record<string, unknown>> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  getColumnWidth(index: number): string {
    return this.widths ? `${this.widths[index] * 100}%` : '50%';
  }

  click() {
    this.clicked.emit({});
  }
}
