import { Provider } from './provider';
import { BaseAttributes } from './_base';
import { Branch } from './branch';
import { User } from './user';
import { Notary } from './notary';
import { CalanLegalRepresentative } from './calanLegalRepresentative';
import { LegalRepresentative } from './legalRepresentative';

// eslint-disable-next-line no-shadow
export enum SpecialtyContractStatus {
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  EXPIRED = 'EXPIRED',
  CLOSED = 'CLOSED',
  REJECTED = 'REJECTED',
}

// eslint-disable-next-line no-shadow
export enum SignatureStatus {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  REJECTED = 'REJECTED',
}

// eslint-disable-next-line no-shadow
export enum NoticeLetterStatus {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
}

export interface SpecialtyContract extends BaseAttributes {
  id: number;
  branch: Branch;
  contractor: Provider;
  sendBy: User;
  status: SpecialtyContractStatus;
  startDate: string;
  notary: Notary;
  legalRepresentationDate: Date;
  legalRepresentationAttachment: string;
  firstLegalRepresentative: LegalRepresentative;
  firstLegalRepresentativeAttachment: string;
  firstLegalRepresentativeSignatureStatus: SignatureStatus;
  secondLegalRepresentative: LegalRepresentative;
  secondLegalRepresentativeAttachment: string;
  secondLegalRepresentativeSignatureStatus: SignatureStatus;
  calanFirstLegalRepresentative: CalanLegalRepresentative;
  calanFirstLegalRepresentativeSignature: SignatureStatus;
  calanSecondLegalRepresentative: CalanLegalRepresentative;
  calanSecondLegalRepresentativeSignature: SignatureStatus;
  draftUpdatedBy: User;
  draftUpdatedAt: string;
  sendToReviewBy: User;
  sendToReviewAt: string;
  requestedBy: User;
  requestedAt: string;
  comment: string;
  signedPdf: string;
  isDigital: boolean;
  evidenceURL: string;
  noticeLetterStatus: NoticeLetterStatus;
  noticeLetterURL: string;
}
