/*
 * File: budgetGroup.ts
 * Project: galilea-centro
 * File Created: Friday, 31st January 2020 12:02:53 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import { Budget } from './budget';
import { Branch } from './branch';
import { Restriction, BGActivity, ActivityType, repairBGActivity, Activity } from './activity';
import { Lot } from './lot';
import { MaterialPriceAgreement } from './priceAgreement';
import { Provider } from './provider';
import { MaterialStatus } from './material';
// eslint-disable-next-line no-shadow
export enum BudgetGroupStatus {
  /**
   * Budget group is being worked on
   */
  DRAFT = 'DRAFT',
  /**
   * Budget group is waiting approval from constructor
   */
  PENDING_CONSTRUCTOR = 'PENDING_CONSTRUCTOR',
  /**
   * Budget group is waiting approval from production department
   */
  PENDING_PRODUCTION = 'PENDING_PRODUCTION',
  /**
   * Budget group is waiting final approval by the CEO
   */
  PENDING_CEO = 'PENDING_CEO',
  /**
   * Budget group has been approved and is active for purchases and tickets
   */
  ACTIVE = 'ACTIVE',
  /**
   * Budget group has been rejected
   */
  REJECTED = 'REJECTED',
  /**
   * Budget group has been closed for purchases and production
   */
  CLOSED = 'CLOSED',
}

// eslint-disable-next-line no-shadow
export enum BudgetGroupStatusName {
  DRAFT = 'Borrador',
  PENDING_CONSTRUCTOR = 'Pendiente aprobación obra',
  PENDING_PRODUCTION = 'Pendiente aprobación primera firma gerencia',
  PENDING_CEO = 'Pendiente aprobación segunda firma gerencia',
  ACTIVE = 'Activo',
  REJECTED = 'Rechazado',
  CLOSED = 'Cerrado',
}

// eslint-disable-next-line no-shadow
export enum BudgetGroupType {
  LABOR = 'LABOR',
  WAREHOUSE = 'WAREHOUSE',
  HOUSE = 'HOUSE',
  URBANIZATION = 'URBANIZATION',
  SERVICE = 'SERVICE',
  BUILDING = 'BUILDING',
  OTHER = 'OTHER',
  HOUSE_REPAIR = 'HOUSE_REPAIR',
  URBANIZATION_REPAIR = 'URBANIZATION_REPAIR',
  BUILDING_REPAIR = 'BUILDING_REPAIR',
  OTHER_REPAIR = 'OTHER_REPAIR',
}

// eslint-disable-next-line no-shadow
export enum RestrictionType {
  FOUNDATIONFORMAT = 'FOUNDATIONFORMAT',
  HOUSEVARIANT = 'HOUSEVARIANT',
  ORIENTATION = 'ORIENTATION',
  FIRSTFLOORCOLOR = 'FIRSTFLOORCOLOR',
  SECONDFLOORCOLOR = 'SECONDFLOORCOLOR',
  THERMALZONE = 'THERMALZONE',
  WINDOW = 'WINDOW',
  SANITARY = 'SANITARY',
}

export const activityTypeToBudgetGroupType: Record<ActivityType, BudgetGroupType> = {
  [ActivityType.house]: BudgetGroupType.HOUSE,
  [ActivityType.urba]: BudgetGroupType.URBANIZATION,
  [ActivityType.other]: BudgetGroupType.OTHER,
  [ActivityType.warehouse]: BudgetGroupType.WAREHOUSE,
  [ActivityType.service]: BudgetGroupType.SERVICE,
  [ActivityType.house_repair]: BudgetGroupType.HOUSE,
  [ActivityType.urba_repair]: BudgetGroupType.URBANIZATION,
  [ActivityType.other_repair]: BudgetGroupType.OTHER_REPAIR,
  [ActivityType.labor]: BudgetGroupType.LABOR,
  [ActivityType.building]: BudgetGroupType.BUILDING,
  [ActivityType.building_repair]: BudgetGroupType.BUILDING,
};

//Use to query outside ppto module
export interface BudgetGroup {
  id: number;
  budgets?: Budget[];
  name: string;
  branchId?: number;
  branch?: Branch;
  status?: BudgetGroupStatus;
  project?: {
    id: number;
  };
  type?: BudgetGroupType;
  ceoReviewedAt?: string;
  totalAmount?: number;
}

export interface BGActivityMaterial {
  quantity: number;
  price: number;
  originalPrice?: number;
  restriction: Restriction;
  activities?: {
    name: string;
    materialQuantity: number;
  }[];
  material: {
    id: number;
    name: string;
    quantificationMetric: string;
    status?: MaterialStatus;
  };
  priceAgreements: MaterialPriceAgreement[];
  totalActivitiesRepetitions?: number;
  deleted?: boolean;
}

export interface BGActivityMaterialAggregated extends BGActivityMaterial {
  totalQuantity: number;
}

export interface MaterialAgreededPriceDifference {
  materialId: number;
  materialName: string;
  currentPrice: number;
  agreededPrice: number;
}

export interface RestrictionObj {
  name: string;
  prevtext: string;
  type: RestrictionType;
}

//Use for ppto module
export interface BGDetails {
  id: number;
  name: string;
  status: BudgetGroupStatus;
  type: string;
  createdAt: string;
  creatorComment: string;
  requestedReviewAt: string;
  constructorReviewedAt: string;
  productionReviewedAt: string;
  ceoReviewedAt: string;
  phases: {
    id: number;
    name: string;
  }[];
  requestedReviewBy: {
    name: string;
  };
  constructorReviewedBy: {
    name: string;
  };
  productionReviewedBy: {
    name: string;
  };
  ceoReviewedBy: {
    name: string;
  };
  budgetExceedLimitPercentage: number;
  createdBy: {
    name: string;
  };
  branch: {
    id: number;
    name: string;
  };
  previousBudgetGroup: BGDetails;
  comparatorBudgetGroup: BGDetails;
  project: {
    id: number;
    name?: string;
  };
  budgets: {
    lots: Lot[];
    baseUnitCubication: number;
  }[];
  bgActivities: BGActivity[];
  attachmentURLs: string[];
}
export interface RepairBGDetails {
  id: number;
  name: string;
  status: BudgetGroupStatus;
  type: BudgetGroupType;
  createdAt: string;
  creatorComment: string;
  requestedReviewAt: string;
  productionReviewedAt: string;
  ceoReviewedAt: string;
  requestedReviewBy: {
    name: string;
  };
  productionReviewedBy: {
    name: string;
  };
  ceoReviewedBy: {
    name: string;
  };
  budgetExceedLimitPercentage: number;
  createdBy: {
    name: string;
  };
  branch: {
    id: number;
    name: string;
  };
  previousBudgetGroup: BGDetails;
  project: {
    id: number;
    name?: string;
  };
  budgets: {
    id: number;
    name: string;
  }[];
  bgActivities: repairBGActivity[];
  fortuitous: boolean;
  attachmentURLs: string[];
  repairActivityId: number;
  responsibles: Responsible[];
  activityToRepair: Activity;
}
interface Responsible {
  provider: Provider;
  budgetIds: number[];
  discountAmount: number;
}
