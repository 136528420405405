import { AfterViewInit, Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { Orientation, slideAnimation } from '@app/animations';
import { delay } from 'rxjs/operators';
import { AnimationService } from '@services/animation.service';
import { LoaderService } from '@services/loader.service';
import { GraphqlService } from '@services/graphql.service';
import { ConcurrentLoaderService } from './services/concurrentLoader.service';
import { AuthService } from './services/auth.service';
import { MessagingService } from './services/messaging.service';
import { SimpleConfirmComponent } from './views/reusable/modals/simple-confirm/simple-confirm.component';
import { NavigationEnd, Router } from '@angular/router';
import { getFromLocalStorage, getFromSessionStorage, saveToSessionStorage } from './utils';
// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideAnimation],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'galilea-centro';
  loader = false;
  concurrentLoader = false;
  apolloLoaded = false;
  orientation = Orientation.void;
  // private ngUnsubscribe = new Subject<void>();

  constructor(
    private updates: SwUpdate,
    private snackBar: MatSnackBar,
    private animation: AnimationService,
    private loaderService: LoaderService,
    private concurrentLoaderService: ConcurrentLoaderService,
    private apollo: GraphqlService,
    private auth: AuthService,
    private messaging: MessagingService,
    public dialog: MatDialog,
    public router: Router,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-JRS3894695', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.apollo.lodaded$.subscribe((status) => {
      this.apolloLoaded = status;
    });
    if ('serviceWorker' in navigator) {
      this.updates.versionUpdates.subscribe((versionEvent) => {
        if (versionEvent.type === 'VERSION_READY') {
          this.showNewUpdateModal();
        }
      });
    }
    if (await this.auth.isLogged()) this.messaging.getPermission();
    if (!getFromSessionStorage('branchId')) {
      saveToSessionStorage('branchId', getFromLocalStorage('branchId'));
    }
  }

  showNewUpdateModal(): void {
    this.dialog
      .open(SimpleConfirmComponent, {
        width: '390px',
        disableClose: true,
        panelClass: 'modal-dark-theme',
        data: {
          title: '¡Nueva actualización disponible!',
          content:
            // eslint-disable-next-line max-len
            ['Se recomienda aplicar esta actualización para prevenir errores provenientes de versiones anteriores.'],
          centered: true,
          decline: 'CERRAR',
          accept: 'ACTUALIZAR',
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe((result) => {
        if (result?.confirm) {
          location.reload();
        } else {
          const update = this.snackBar.open('Nueva versión de la aplicación', `Actualizar`, {
            panelClass: ['snack-bar-container-colored'],
          });
          update.onAction().subscribe(() => {
            location.reload();
          });
        }
      });
  }

  ngAfterViewInit(): void {
    this.loaderService
      .getLoader()
      .pipe(delay(0))
      .subscribe((loader) => {
        if (this.loader !== loader) {
          this.loader = loader;
        }
      });

    this.concurrentLoaderService
      .getLoader()
      .pipe(delay(0))
      .subscribe((loader) => {
        if (this.concurrentLoader !== loader) {
          this.concurrentLoader = loader;
        }
      });

    this.animation
      .getAnimation()
      .pipe()
      .subscribe((val) => {
        this.orientation = val;
      });
  }

  prepareRoute() {
    return this.orientation;
  }

  ngOnDestroy(): void {
    // this.ngUnsubscribe.next();
    // this.ngUnsubscribe.complete();
  }
}
