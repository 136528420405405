/*
 * File: select-multiple.component.ts
 * Project: galilea-centro
 * File Created: Wednesday, 21st April 2021 1:04:22 pm
 * Author: Fabián Ulloa (fabian@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Selection } from '../select/select.component';
import { FormControl } from '@angular/forms';
import Fuse from 'fuse.js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { map } from 'lodash';

@Component({
  selector: 'app-multi-select',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectMultipleComponent implements OnInit, OnChanges, OnDestroy {
  protected _onDestroy = new Subject<void>();
  @Input() selections: Selection[];
  @Input() attr: string;
  @Input() title: string;
  @Input() currentValue: string[] = [];
  @Input() disabled?: boolean = false;
  @Input() searchable?: boolean = false;
  @Input() searchMinLength?: number = 2;
  @Input() displayResultsEmptySearch?: boolean = false;
  @Input() showToggleAllCheckbox?: boolean = false;
  @Input() toggleAllCheckboxTooltipMessage?: string = 'Seleccionar / quitar todos';
  @Input() placeholder?: string = '';
  @Input() panelClass?: string = 'select-multiple-component__search-box';
  @Output() selected: EventEmitter<{ selection: Selection['value'][]; attr: string }> = new EventEmitter();
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;

  selectedOption = new FormControl<string[]>([]);
  searcherControl = new FormControl<string>('');

  //TODO: Use new search service
  fuseSearch: Fuse<Selection>;
  fuseOptions: Fuse.IFuseOptions<Selection> = {
    threshold: 0.3,
    minMatchCharLength: 1,
    useExtendedSearch: true,
    isCaseSensitive: false,
    ignoreLocation: true,
    keys: [
      { name: 'name', weight: 0.8 },
      { name: 'optional', weight: 0.2 },
    ],
  };
  filteredSelections: Selection[];

  constructor() {}

  ngOnInit(): void {
    if (this.searchable) {
      this.fuseSearch = new Fuse(this.selections, this.fuseOptions);
      this.filteredSelections = this.displayResultsEmptySearch ? this.selections : [];
      this.searcherControl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
        this.search();
      });
    }
    if (!this.currentValue) {
      this.currentValue = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedOption.setValue(this.currentValue);
    if (changes.selections) {
      this.fuseSearch = new Fuse(this.selections, this.fuseOptions);
      this.filteredSelections = this.displayResultsEmptySearch ? this.selections : [];
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  search(): void {
    const search = this.searcherControl.value;
    if (search.length < this.searchMinLength) {
      this.filteredSelections = this.displayResultsEmptySearch ? this.selections : [];
      return;
    }
    this.filteredSelections = search
      ? map(this.fuseSearch.search(search), 'item')
      : this.displayResultsEmptySearch
      ? this.selections
      : [];
  }

  selectionChange(selection: Selection['value'][]): void {
    this.selected.emit({ selection, attr: this.attr });
  }

  toggleSelectAll(event: boolean): void {
    this.selectionChange(event ? map(this.selections, 'value') : []);
  }

  displayFn(value?: number): string {
    const selection = this.selections.filter((s) => String(s.value) === String(value))[0];
    return selection?.name;
  }
}
