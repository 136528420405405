<div class="app-form-card" [ngSwitch]="data.type">
  <app-one-column-static-card
    *ngSwitchCase="'info'"
    [title]="data.title"
    [body]="getContentAttribute('info')"
    [size]="data.size"
  ></app-one-column-static-card>
  <app-one-column-button-card
    *ngSwitchCase="'button'"
    [title]="data.title"
    [body]="getContentAttribute('buttonText')"
    [icon]="getContentAttribute('buttonIcon')"
    (selected)="onButtonClick()"
  ></app-one-column-button-card>
  <app-one-column-dropdown-card
    *ngSwitchCase="'dropdown'"
    [title]="data.title"
    [selections]="getContentSelections()"
    [currentValue]="getContentAttribute('currentValue')"
    [searchable]="getSearchable()"
    [enableClearButton]="getContentAttribute('enableClearButton')"
    (selected)="onDropdownSelect($event)"
    [icon]="data.icon"
    [iconCallback]="data.iconCallback"
    [iconTooltip]="data.iconTooltip"
    [isInvalid]="getContentAttribute('isInvalid')"
  ></app-one-column-dropdown-card>
  <app-one-column-card
    *ngSwitchCase="'input'"
    [title]="data.title"
    [current]="getCurrentValue()"
    (selected)="onInputChange($event.value)"
    [pattern]="getContentAttribute('pattern')"
    [regex]="getContentAttribute('regex')"
    [placeholder]="getContentAttribute('placeholder')"
    [isInvalid]="getContentAttribute('isInvalid')"
    [size]="data.size"
  >
  </app-one-column-card>
  <app-two-column-number-card
    *ngSwitchCase="'numberinput'"
    [title]="data.title"
    [current]="getCurrentValue()"
    (selected)="onInputChange($event.value)"
    [sufixText]="getContentAttribute('sufixText')"
    [interval]="getContentAttribute('interval')"
    [min]="getContentAttribute('min')"
    [max]="getContentAttribute('max')"
    [softMin]="getContentAttribute('softMin')"
    [softMax]="getContentAttribute('softMax')"
    [precision]="getContentAttribute('precision')"
  >
  </app-two-column-number-card>
  <app-one-column-upload-card
    *ngSwitchCase="'file'"
    [title]="data.title"
    [meta]="getContentAttribute('meta')"
    [url]="getContentAttribute('currentValue')"
    [disabled]="getContentAttribute('disabled')"
    (result)="onInputChange($event.url)"
  >
  </app-one-column-upload-card>
  <app-two-column-static-card
    *ngSwitchCase="'doubleinfo'"
    [title]="data.title"
    [column1]="getContentAttribute('left')"
    [column2]="getContentAttribute('right')"
    [widths]="getContentAttribute('widths')"
    [size]="data.size"
  >
  </app-two-column-static-card>
  <app-two-column-button-card
    *ngSwitchCase="'infoandbutton'"
    [title]="data.title"
    [column]="getContentAttribute('info')"
    [buttonText]="getContentAttribute('buttonText')"
    (selected)="onButtonClick()"
    [size]="data.size"
  >
  </app-two-column-button-card>
  <app-one-column-dropdown-multiselect-card
    *ngSwitchCase="'multiselect'"
    [title]="data.title"
    [selections]="getContentSelections()"
    [currentValue]="getContentAttribute('currentValue')"
    (selected)="onMultiselectSelect($event)"
  ></app-one-column-dropdown-multiselect-card>
  <app-one-column-date-card
    *ngSwitchCase="'datepicker'"
    [title]="data.title"
    [current]="getCurrentValue()"
    (selected)="onInputChange($event.value)"
  >
  </app-one-column-date-card>
</div>
