/*
 * File: loader.service copy.ts
 * Project: galilea-centro
 * File Created: Thursday, 23rd January 2020 12:41:09 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Injectable } from '@angular/core';
import { ErrorModalComponent } from '@app/views/reusable/modals/error-modal/error-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkErrorService {
  private dialogRef: MatDialogRef<ErrorModalComponent>;
  constructor(public dialog: MatDialog) {}

  public showError() {
    if (this.dialogRef) {
      return;
    }
    this.dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: 'Error de conexión',
        message: 'Comprueba tu conexión a internet e intenta nuevamente.',
      },
    });
    this.dialogRef.afterClosed().pipe(first(), (obs) => {
      this.dialogRef = null;
      return obs;
    });
  }

  public hideError() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
    }
  }
}
