import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import gql from 'graphql-tag';
import { OperationVariables, WatchQueryOptions, SubscriptionOptions, QueryOptions } from '@apollo/client/core';
import { DocumentNode } from 'graphql';
import { BehaviorSubject } from 'rxjs';
import { MutationBaseOptions, MutationOptions } from '@apollo/client/core/watchQueryOptions';
import { EmptyObject } from 'apollo-angular/types';

type WatchQueryOptionsLite<R> = Omit<WatchQueryOptions<R>, 'query'>;
type QueryOptionsLite<R> = Omit<QueryOptions<R>, 'query'>;
type SubscriptionBaseOptionsLite<R> = Omit<SubscriptionOptions<R>, 'query'>;
@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  lodaded$ = new BehaviorSubject(false);

  constructor(private apollo: Apollo) {}

  // eslint-disable-next-line @typescript-eslint/ban-types
  query<T = Record<string, unknown>, R = Record<string, unknown>>(
    sql: string | DocumentNode,
    options: WatchQueryOptionsLite<R> = null,
  ): QueryRef<T, R> {
    if (typeof sql === 'string') {
      return this.apollo.watchQuery<T, R>({
        query: gql`query ${sql}`,
        ...options,
      });
    }
    return this.apollo.watchQuery<T, R>({
      query: sql,
      ...options,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  lazyQuery<T = Record<string, unknown>, R = Record<string, unknown>>(
    sql: DocumentNode,
    options: QueryOptionsLite<R> = null,
  ) {
    return this.apollo.query<T>({
      query: sql,
      ...options,
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  mutation<T = Record<string, any>, V = OperationVariables>(
    sql: string | DocumentNode,
    options?: Omit<MutationOptions<T, V>, 'mutation'>,
  ) {
    if (typeof sql === 'string') {
      return this.apollo.mutate<T, V>({
        mutation: gql`mutation ${sql}`,
        ...options,
      });
    }
    return this.apollo.mutate<T, V>({
      mutation: sql,
      ...options,
    });
  }

  async reset(): Promise<void> {
    const client = this.apollo.client;
    await client.clearStore();
    client.stop();
    await client.cache.reset();
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  subscribe<T = Record<string, unknown>, R = Record<string, unknown>>(
    sql: string | DocumentNode,
    options: SubscriptionBaseOptionsLite<R> = {},
  ) {
    if (typeof sql === 'string') {
      return this.apollo.subscribe<T, R>({
        query: gql`subscription ${sql}`,
        ...options,
      });
    }
    return this.apollo.subscribe<T, R>({
      query: sql,
      ...options,
    });
  }
}
