/*
 * File: gravel-routes.guard.ts
 * Project: galilea-centro
 * File Created: Monday, 18th May 2020 10:30:54 pm
 * Author: Kevin Johnson (kjjohnson@uc.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Injectable } from '@angular/core';
import { Router, CanActivateChild, ActivatedRouteSnapshot } from '@angular/router';
import { first } from 'rxjs/operators';

import { NavigationService } from '@services/navigation.service';
import { GravelService } from '@services/gravel.service';

@Injectable()
export class GravelRoutesGuard implements CanActivateChild {
  constructor(
    private gravelService: GravelService,
    private navigationService: NavigationService,
    private router: Router,
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
    const { path } = route.routeConfig;
    const targetPath = '/terreno/aridos/' + path; //TODO extend this guard for ticket flow
    return new Promise((resolve) => {
      this.gravelService
        .getSelections()
        .pipe(first())
        .subscribe((selections) => {
          const variablesToVerify = this.navigationService.getStoreVariablesNames(targetPath, selections.activityType);
          const can = variablesToVerify.every((variable: string) => Boolean(selections[variable]));
          if (!can) {
            const firstRoute = this.navigationService.getFirstRoute(null);
            this.router.navigateByUrl(firstRoute);
          }
          resolve(can);
        });
    });
  }
}
