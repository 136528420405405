<mat-card [ngClass]="size === 'double' ? 'double' : 'simple'">
  <p>{{ title }}</p>
  <div class="body">
    <div class="column1">
      <div isEllipsisActive [matTooltip]="column" [matTooltipShowDelay]="500">{{ column }}</div>
    </div>
    <div class="column2">
      <div class="button" (click)="click()" [ngClass]="{ 'disable-button': disableButton }">
        {{ buttonText }}
        <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
      </div>
    </div>
  </div>
</mat-card>
