import { createAction, props } from '@ngrx/store';
import { Contractors } from '@models/contractor';
import { MaterialItems, Materials } from '@models/materialItems';
import { TicketType, Explanation } from '@models/ticket';
import { BudgetGroup } from '@models/budgetGroup';
import { Budget } from '@models/budget';

export const loadTickets = createAction('[Ticket] Load Tickets');

export const SelectContractor = createAction('[Ticket] Set Selected Contractor', props<{ contractor: Contractors }>());

export const EditReciever = createAction('[Ticket] Set Contractor Reciever', props<{ picker: string }>());

export const SelectActivity = createAction('[Ticket] Set Selected Activity', props<{ activity: MaterialItems }>());

export const SelectBudgetGroup = createAction(
  '[Ticket] Set Selected BudgetGroup',
  props<{ budgetGroup: BudgetGroup }>(),
);

export const SelectBudgets = createAction('[Ticket] Set Selected Budgets', props<{ budgets: Budget[] }>());

export const ResetActivity = createAction('[Ticket] Reset Selected BudgetGroup, Lots, Materials and Explanation');

export const SelectMaterials = createAction(
  '[Ticket] Set Selected Materials',
  props<{ selectedMaterials: Materials[] }>(),
);

export const ChangeTicketType = createAction('[Global] Change Ticket Type', props<{ ticketType: TicketType }>());

export const TicketHouse = createAction('[Global] Ticket Type House');

export const TicketUrba = createAction('[Global] Ticket Type Urba');

export const TicketOther = createAction('[Global] Ticket Type Other');

export const SelectExplanation = createAction(
  '[Ticket] Set Selected Explanation',
  props<{ explanation: Explanation }>(),
);

export const SelectUrbaActivityQuantity = createAction(
  '[Ticket] Set UrbaActivityQuantity',
  props<{ urbaActivityQuantity: number }>(),
);
