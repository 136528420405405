/* eslint-disable no-shadow */
/*
 * File: ticketStatus.enum.ts
 * Project: galilea-centro
 * File Created: Thursday, 5th March 2020 12:33:00 pm
 * Author: Jurgen Heysen (jurgen@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

/**
 * Known states of a ticket in the system
 * Can be used to translate back-end's response
 *
 * @export
 * @enum {number}
 */
export enum TicketStatus {
  /**
   * Ticket is waiting approval by the constructor
   */
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  /**
   * Ticket is ready and waiting to be picked in warehouse
   */
  WAITING_PICKUP = 'WAITING_PICKUP',
  /**
   * Ticket has been rejected by the constructor and is no longer valid
   */
  REJECTED = 'REJECTED',
  /**
   * Ticket is being prepared in the warehouse
   */
  IN_PREPARATION = 'IN_PREPARATION',
  /**
   * Ticket has already been delivered
   */
  DELIVERED = 'DELIVERED',
  /**
   * Ticket has expired and is no longer valid
   */
  EXPIRED = 'EXPIRED',
}

export function translateTicketStatus(s: string): TicketStatus {
  switch (s?.toUpperCase()) {
    case 'WAITING_PICKUP':
      return TicketStatus.WAITING_PICKUP;
    case 'IN_PREPARATION':
      return TicketStatus.IN_PREPARATION;
    case 'DELIVERED':
      return TicketStatus.DELIVERED;
    case 'PENDING_APPROVAL':
      return TicketStatus.PENDING_APPROVAL;
    case 'EXPIRED':
      return TicketStatus.EXPIRED;
    case 'REJECTED':
      return TicketStatus.REJECTED;
    default:
      return null;
  }
}
