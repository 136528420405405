/*
 * File: niceDate.pipe.ts
 * Project: galilea-centro
 * File Created: Tuesday, 5th May 2020 10:09:34 am
 * Author: Kevin Johnson (kevin@inventures.cl)
 * -----
 * Copyright 2019 - 2020 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'niceRut',
})
export class NiceRutPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) return '';
    let rut = value.replace(/\.|-|^0+/g, '');
    if (rut.length < 2) return rut.toUpperCase();
    // Add dash
    rut = [rut.slice(0, rut.length - 1), rut.slice(rut.length - 1)].join('-');
    // Add dots if missing
    if ((rut.match(/\./g) || []).length < 2) rut = this.addDots(rut.split('-')[0]) + rut.slice(rut.length - 2);
    // Last digit "k" always uppercased
    return rut.toUpperCase();
  }

  private addDots = (rut: string) => rut.replace(/\./g, '').replace(/\d(?=(?:\d{3})+$)/g, '$&.');

  public validate = (fullRut: string): boolean => {
    // Validate basic format
    if (!/^[0-9]{1,2}\.?[0-9]{3}\.?[0-9]{3}-?[0-9kK]{1}$/.test(fullRut)) return false;
    // Validate last digit
    const len = fullRut.length;
    const [rut, dv] = fullRut.includes('-') ? fullRut.split('-') : [fullRut.slice(0, len - 1), fullRut.slice(len - 1)];
    return this.dv(+rut.replace(/\./g, '')) === dv.toLowerCase();
  };

  private dv = (T: number): string => {
    let M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? String(S - 1) : 'k';
  };
}
